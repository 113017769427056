import React, { useEffect ,useState} from 'react'
import { getAllbooking } from '../../api/Function';
import { toast } from "react-toastify";
import OrderCard from '../../component/cards/OrderCard';

const Order = () => {
  const[allOrders,setAllorders]=useState([])
  const userId = localStorage.getItem("userID");

  const handleGetAllBooking= async () => {
      let payload = {
        userId: userId,
      };
      try {
        const result = await getAllbooking(payload);
        if(result.status===200){
          setAllorders(result?.data?.bookings);
        }
        else{
          toast.error("something went wrong!,please try again")
        }
       
      } catch (error) {
        if(error?.status===404){
        toast.warning("No bookings found ");

        }
        //toast.error("Network Error ");

      } 
    };
    useEffect(()=>{
      handleGetAllBooking();

    },[])

  return (
    <div className='order-main-wrapper'>
      Your Orders
{allOrders && allOrders?.map((item,key)=>{
  return   <OrderCard data={item} key={key}/>

})
}
    </div>
  )
}

export default Order;