import React from "react";
import WorkStrategyCard from "../../../component/cards/WorkStrategyCard";
import { WorkStrategyCarddata } from "../../../data";
const AboutIntro = () => {
  return (
    <div className="service-about-container">
      <span className="heading-about">Our Work Process </span>
      <div className="card-container">
        {WorkStrategyCarddata.map((item, key) => {
          return <WorkStrategyCard data={item} />;
        })}
      </div>
    </div>
  );
};

export default AboutIntro;
