import React from "react";
import { companyLogo } from "../../../data";
import Marquee from "react-marquee-slider";

const WorkedComapny = () => {
  return (
    <div className="worked-company-container">
      <span className="text">
        Trusted by Our <span className="active"> Clients </span>
      </span>

      <div className="company-logo-box">
        <Marquee velocity={15} resetAfterTries={3} direction="rtl">
          {companyLogo?.map((item, index) => {
            return (
              <div className="company-logo" key={index}>
                <img
                  src={item?.image}
                  alt="company-logo"
                  className="image"
                  lazy="loading"
                />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default WorkedComapny;
