import { useEffect, useRef } from "react";
import crypto from "crypto-js";
import { verifyPayment } from "../api/Function";
import { toast } from "react-toastify";

const RenderRazorpay = ({
  orderId,
  keyId,
  currency,
  amount,
  onClose = () => {},
  EventIDs = [],
  productIDs = [],
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const rzpInstance = useRef(null);
  const secret = process.env.REACT_APP_RAZORPAY_KEY_SECRET;
  const userId = localStorage.getItem("userID");

  const displayRazorpay = async (options) => {
    const rzp1 = new window.Razorpay(options);
    rzpInstance.current = rzp1;

    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });

    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    rzp1.open();
  };

  const handlePayment = async (status, orderDetails = {}) => {
    const payload = {
      status,
      orderDetails: {
        ...orderDetails,
        paymentId: paymentId?.current,
        userId,
        amount,
        eventIDs: EventIDs,
        productIDs: productIDs,
      },
    };
    try {
      const result = await verifyPayment(payload);
      if (result?.status === 200) {
        rzpInstance.current?.close();
        onClose(true);
        toast.success("Payment Successful");
      }
    } catch (error) {
      onClose(false);

      console.error("Payment verification failed", error);
    }
  };

  const options = {
    key: keyId,
    amount,
    currency,
    name: "Anoop",
    order_id: orderId,
    handler: (response) => {
      paymentId.current = response?.razorpay_payment_id;

      const succeeded =
        crypto
          .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, secret)
          .toString() === response.razorpay_signature;

      if (succeeded) {
        paymentId.current = response?.razorpay_payment_id;
        handlePayment("succeeded", {
          orderId,
          paymentId: response?.razorpay_payment_id,
          signature: response?.razorpay_signature,
        });
      } else {
        handlePayment("failed", {
          orderId,
          paymentId: response.razorpay_payment_id,
        });
      }
    },
    modal: {
      confirm_close: true,
      ondismiss: () => {
        handlePayment("cancelled");
      },
    },

    retry: {
      enabled: false,
    },
    timeout: 900,
    theme: {
      color: "#61dafb",
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
