import React from "react";
import Projects from "../about/Projects";

const Work = () => {
  const videoId = "19ldBltwEDY3h52wtd4phtbbYElPPY3Qj";  // Your video ID
  const videoUrl = `https://drive.google.com/uc?export=download&id=${videoId}`;

  return (
    <div className="">
    <Projects/>
    </div>
  );
};

export default Work;
