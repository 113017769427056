import React, { useState } from "react";
import Input from "../../component/Input";
import { createProduct } from "../../api/Function";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/Loader";

const CreateProduct = () => {
  const navigate = useNavigate();
  const Id = localStorage.getItem("userID");
  const[loading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    category: "",
    stock: "",
    brand: "",
    userId: Id,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        newErrors[key] = `${key} is required.`;
      }
    });
    if (!image) {
      newErrors.image = "Image is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
        setIsLoading(true);
      try {
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
          data.append(key, formData[key]);
        });

        const fileInput = document.getElementById("image-upload");
        if (fileInput?.files[0]) {
          data.append("image", fileInput.files[0]);
        }

        const res = await createProduct(data);
        console.log(res, "dghgfvbsfvvj", res.status);

        if (res.status === 201) {
          toast.success("product created successfully");
         navigate("/product");
        }
      } catch (error) {
        console.error("Error creating event:", error);
        toast.error("Failed to create event");
      }
      finally{
        setIsLoading(false)
      }
    }
  };

  return (
    <div className="create-event-wrapper">
      <div className="container">
        <h2 className="title">Add Product</h2>
        <div className="input-wrapper">
         

          <div className="input-box">
            <Input
              label="Add Name "
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Product Name"
              errorText={errors.name}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Product Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="About the Product"
              errorText={errors.description}
            />
          </div>
   
   
          <div className="input-box">
            <Input
              label="Add Product price"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              placeholder="Add price"
              errorText={errors.price}
            />
          </div>
          
          <div className="input-box">
            <Input
              label="Add Product category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              placeholder="category"
              errorText={errors.category}
            />
          </div>
          
          <div className="input-box">
            <Input
              label="Add Product Stock"
              name="stock"
              value={formData.stock}
              onChange={handleInputChange}
              placeholder="Add product stock"
              errorText={errors.stock}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Product brand"
              name="brand"
              value={formData.brand}
              onChange={handleInputChange}
              placeholder="Add product Brand"
              errorText={errors.brand}
            />
          </div>
          <div className="image-box">
            <label htmlFor="image-upload" className="image-label">
              Upload Product Image
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {errors.image && <p className="error-text">{errors.image}</p>}
          </div>
        </div>

        <button className="submit-button" onClick={() => handleSubmit()}>
          Submit
        </button>
      </div>
      <Loader show={loading}/>
    </div>
  );
};

export default CreateProduct;
