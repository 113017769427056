import React from 'react'

const WorkStrategyCard = ({data}) => {
  return (
    <div className='Work-strategy-card-container'>
        
<div className='icon-box'>
    <img src={data.image} alt="icon" className='icon'/>
     </div>
  <span className='heading'> {data.title}</span>
  <span className='para'> {data.about}</span>
    </div>
  )
}

export default WorkStrategyCard