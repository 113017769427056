import React from "react";
import { ChangeDataFormat, utcToIstTime } from "../../utils/helper/function";
import { eventposter2 } from "../../assets/image";

const OrderCard = ({ data, key }) => {
  const orderTime = utcToIstTime(data?.createdAt);
  const OrderItems = data?.items || [];
  const TotalAmount = data?.amount / 100 || "";

  return (
    <div key={key} className="order-card-wrapper">
      <span className="order-time">
        Ordered at : <span className="time">{orderTime || ""} </span>
      </span>
      <span className="order-time">
        Total Amount : ₹ <span className="time">{TotalAmount || ""} </span>
      </span>
      <div className="order-item-box">
        {OrderItems &&
          OrderItems?.map((item, key) => {
            return (
              <div className="order-item">
                <div className="image-container">
                  <img
                    src={item?.eventId ? item?.eventId?.image : eventposter2}
                    alt="poster"
                    className="poster"
                    loading="lazy"
                  />
                </div>
                <div className="info-container">
                  <span className="cart-name">
                    {item?.eventId ? item?.eventId?.eventName : ""}
                  </span>
                  <span className="cart-venue">
                    {item?.eventId ? item?.eventId?.location : ""}
                  </span>
                  <span className="total-price">
                    Price:
                    <span className="price">
                      ₹ {item?.eventId ? item?.eventId?.price : ""}
                    </span>
                  </span>
                  <span> Quantity : {item?.ticketCount}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OrderCard;
