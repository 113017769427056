import React, { useEffect, useState } from "react";
import CartCard from "../../component/cards/CartCard";
import PaymentButton from "../../component/button/PaymentButton";
import {
  makePaymentOrder,
  getAllCartsItem,
  removeCartItem,
  updateCartItem,
} from "../../api/Function";
import RenderRazorpay from "../../component/RenderRazorpay";
import { toast } from "react-toastify";
import EmptyCart from "./EmptyCart";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartItem, setCartItem] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isOrderCreate, setIsOrderCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate=useNavigate();
  const userId = localStorage.getItem("userID");

  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const getTotalPrice = () => {
    const total =cartItem.length>0 &&  cartItem?.reduce((accumulator, item) => {
      if (item?.eventId) {
        accumulator += item.eventId.price * item.ticketCount;
      } else if (item?.productId) {
        accumulator += item.productId.price * item.quantity;
      }
      return accumulator;
    }, 0);

    setTotalPrice(total);
  };
  const handleGetAllItem = async () => {
    setIsLoader(true);
    let payload = {
      userId: userId,
    };
    try {
      const result = await getAllCartsItem(payload);
      if (result.status === 200) {
        setCartItem(result?.data);
        getTotalPrice();
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoader(false);
    }
  };
  const EventIDs =cartItem.length>0 && cartItem?.filter((item) => item.eventId)?.map((item) => ({
      id: item.eventId._id,
      quantity: item.ticketCount,
    }));
  const ProductIDs =cartItem.length>0 &&  cartItem?.filter((item) => item.productId)?.map((item) => ({
      id: item.productId._id,
      quantity: item.quantity,
    }));

  const handleRemoveItem = async (data) => {
    setIsDelete(true);
    let payload = {
      userId,
      itemId: data?._id,
    };
    try {
      const res = await removeCartItem(payload);
      if (res.status === 200) {
        setCartItem(res?.data?.cart?.items);
        toast.success("Item remove successfully");
      } else {
        toast.error("something went wrong ,please try later");
      }
    } catch (error) {
      console.log(error, "Error");
      toast.error("Internal Server Error");
    } finally {
      setIsDelete(false);
    }
  };
  const handlecountchange = async (data, type) => {
    setIsUpdating(true);
    let payload = {
      userId,
      itemId: data?._id,
      ...(data?.eventId
        ? {
            ticketCount:
              type === "inc" ? data?.ticketCount + 1 : data?.ticketCount - 1,
          }
        : {
            quantity: type === "inc" ? data?.quantity + 1 : data?.quantity - 1,
          }),
    };
    try {
      const result = await updateCartItem(payload);
      if (result.status === 200) {
        setCartItem(result?.data);
      } else {
        toast.error("something went wrong ,please try later");
      }
    } catch (err) {
      console.log(err);
      toast.error("Internal Server Error");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCloseRazorpay = (data) => {
    if (data) {
      setDisplayRazorpay(false);
      navigate("/orders")
    } else {
      toast.error("something went wrong !");
    }
  };

  const handleCreateOrder = async () => {
    setIsOrderCreate(true);
    try {
      let payload = {
        amount: totalPrice,
        userId,
      };
      const response = await makePaymentOrder(payload);
      const data = response.data;
      if (data && data.order_id) {
        setOrderDetails({
          orderId: data?.order_id,
          amount: data?.amount,
          currency: data?.currency,
        });
        setDisplayRazorpay(true);
      } else {
        console.error("Failed to create order, invalid response:", data);
        toast.error("Failed to create order, please try again");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("something went wrong, please try again");
    } finally {
      setIsOrderCreate(false);
    }
  };

  

  useEffect(() => {
    getTotalPrice();
  }, [totalPrice, isLoader, cartItem]);
  useEffect(() => {
    handleGetAllItem();
  }, []);
  console.log(cartItem, "cartItem",);
  return (
    <div className="cart-container-wrapper">
      <div className="heading-box">
        <span className="title">Your Cart</span>
      </div>

      <div className="cart-container-box">
  {isLoader ? (
    <>
      {[...Array(3)].map((_, key) => (
        <CartCard loading={isLoader} key={key} />
      ))}
    </>
  ) : (
    <>
      {cartItem?.length > 0 ? (
        <>
          {cartItem?.map((item) => (
            <div className="cart-container" key={item.id}>
              <CartCard
                loading={isLoader}
                data={item}
                countchange={handlecountchange}
                onDelete={handleRemoveItem}
                isUpdating={isUpdating}
                isproductcard={item?.quantity>0}
              />
            </div>
          ))}
          <div className="payment-box">
            <div className="pay-info">
              <span className="total-price">Total: ₹ {totalPrice}</span>
              {/* <span className="total-price">Apply Coupon Code</span> */}
            </div>
            <div className="payment-button">
              <PaymentButton
                pay={totalPrice}
                OnClick={() => handleCreateOrder()}
                disable={isOrderCreate || isUpdating || isLoader || isDelete}
              />
            </div>
          </div>
        </>
      ) : (
        <EmptyCart />
      )}
    </>
  )}
</div>

  
      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={"rzp_test_tJUtJdrFjgX8wI"}
          onClose={handleCloseRazorpay}
          EventIDs={EventIDs}
          productIDs={ProductIDs}
        />
      )}
    </div>
  );
};

export default Cart;
