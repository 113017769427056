import React, { useEffect, useState } from "react";
import EventCard from "../../component/cards/EventCard";
import { useNavigate } from "react-router-dom";
import { deleteEvent, getAllevents } from "../../api/Function";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";

const Events = () => {
  const navigate = useNavigate();
  const [allEvents, setallEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const isAdmin = localStorage.getItem("isAdmin")==="true";

  const handleCreate = () => {
    navigate("/event/create");
  };
 
  const handleDelete = async (id) => {
    try {
      const res = await deleteEvent(id);
      if (res.status) {
        fetchAllEvents();
        toast.success("Event Deleted Successfully");
      }
    } catch (error) {
      console.log(error, "error");
      toast.error("Event Deletion Failed");
    }
  };

  const fetchAllEvents = async () => {
    setLoading(true);
    try {
      const res = await getAllevents();
      console.log(res, "dghgfvbsfvvj", res.status);
      if (res.status === 200) {
        setallEvents(res.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  return (
    <div className="events-container">
     {isAdmin &&  <button className="create-event" onClick={() => handleCreate()}>
        Create Event
      </button>}
      {loading
        ? [...Array(3)].map((_, key) => (
            <EventCard key={key} data={null} index={key} isLoader={true} isAddingincart={true} />
          ))
        : allEvents?.map((item, key) => (
            <EventCard
              key={key}
              data={item}
              index={key}
              isLoader={false}
              onDelete={handleDelete}
              viewDetails={() => navigate(`/event/${item._id}`)}
            />
          ))}
    </div>
  );
};

export default Events;
