import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Title
);

const BarGraph = ({ bardata }) => {
  if (!bardata || !bardata.labels || !bardata.datasets?.[0]?.data) {
    console.error('Invalid bardata structure:', bardata);
    return <div>Invalid data for the chart</div>;
  }

  const data = {
    labels: bardata.labels||[], 
    datasets: [
      {
        label: 'Daily Sales',
        data: bardata.datasets[0].data||[],
        backgroundColor: 'rgba(54, 162, 235, 0.7)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 100,
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarGraph;
