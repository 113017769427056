import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const CommonAccordian = ({ data }) => {

  return (
    <div className="accordian">
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{data?.question}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <span className="answer">{data?.answer}</span>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CommonAccordian;
