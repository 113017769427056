import React from "react";

const Input = ({
  type = "text",
  value,
  onChange,
  placeholder = "",
  name,
  id,
  label = "",
  className = "",
  disabled = false,
  required = false,
  autoFocus = false,
  onBlur,
  onFocus,
  errorText = "",
  ...rest
}) => {
  return (
    <div className={`input-wrapper ${className}`}>
      {label && <span className="label">{label}</span>}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        id={id}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onFocus={onFocus}
        {...rest} // Additional props like `data-*`, `aria-*`, etc.
        className={`input-field ${errorText ? "input-error" : ""}`}
      />
      {errorText && <span className="error-text">{errorText}</span>}
    </div>
  );
};

export default Input;
