import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";
import { GsapAnimation } from "../../utils/animation/Animation";

const NumberCard = ({ data }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      GsapAnimation("popOut", cardRef.current, { duration: 0.5 });
    }
  }, []);

  return (
    <div className="number-box" ref={cardRef}>
      <span className="number">
        <CountUp start={0} end={data.num} duration={2} separator="+" />+
      </span>
      <span className="text">{data.text}</span>
    </div>
  );
};

export default NumberCard;
