import axios from "axios";

const makeApiCall = async (method, url, data = {}, useAuthHeader = true) => {
  const headers = {};

  if (useAuthHeader) {
    const token = localStorage.getItem("authToken"); //  token is stored in localStorage
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  }

  const config = {
    method,
    url,
    data,
    headers,
  };

  try {
    let result = await axios.request(config);
    return result;
  } catch (err) {
    console.error("API Call Error: ", err);
    throw err; // Propagate the error for further handling
  }
};

export default makeApiCall;
