import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { ChangeDataFormat } from "../../utils/helper/function";
import { eventposter2 } from "../../assets/image";
import Skeleton from "react-loading-skeleton";
import { MdDelete } from "react-icons/md";

const CartCard = ({
  data,
  countchange,
  loading,
  isUpdating,
  onDelete = () => {},
  isproductcard = false,
}) => {
  return isproductcard ? (
    <div className="cart-card-container">
      <div className="image-container">
        {loading ? (
          <Skeleton height={145} width={123} />
        ) : (
          <img
            src={data?.
              productId ? data?.
              productId?.images[0] : eventposter2}
            alt="poster"
            className="poster"
          />
        )}
      </div>

      <div className="info-container">
        {loading ? (
          <>
            <Skeleton height={20} width={150} style={{ marginBottom: "8px" }} />
            <Skeleton height={15} width={200} />
          </>
        ) : (
          <>
            <span className="cart-name">
              {data?.productId ? data?.productId?.name : ""}
            </span>
            <span className="cart-venue">
              {/* {data?.eventId ? data?.eventId?.starttime : ""},{" "}
              {data?.eventId ? ChangeDataFormat(data?.eventId?.date) : ""},{" "}
              {data?.eventId ? data?.eventId?.location : ""} */}
            </span>
          </>
        )}

        <div className="cart-math">
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <span className="total-price">
              Price:
              <span className="price">
                ₹ {data?.productId ? data?.productId?.price : ""}
              </span>
            </span>
          )}
          <div className="cart-number-box">
            {loading || isUpdating ? (
              <Skeleton height={25} width={90} />
            ) : (
              <>
                <div className="box" onClick={() => countchange(data, "dec")}>
                  <FaMinus style={{ color: "#fff" }} />
                </div>
                <div className="box">
                  <span className="number">{data?.quantity}</span>
                </div>
                <div className="box" onClick={() => countchange(data, "inc")}>
                  <FaPlus style={{ color: "#fff" }} />
                </div>
              </>
            )}
          </div>
          {loading ? (
            <Skeleton height={24} width={24} />
          ) : (
            <MdDelete
              color="red"
              style={{ width: 24, height: 24, cursor: "pointer" }}
              onClick={() => onDelete(data)}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="cart-card-container">
      <div className="image-container">
        {loading ? (
          <Skeleton height={145} width={123} />
        ) : (
          <img
            src={data?.eventId ? data?.eventId?.image : eventposter2}
            alt="poster"
            className="poster"
          />
        )}
      </div>

      <div className="info-container">
        {loading ? (
          <>
            <Skeleton height={20} width={150} style={{ marginBottom: "8px" }} />
            <Skeleton height={15} width={200} />
          </>
        ) : (
          <>
            <span className="cart-name">
              {data?.eventId ? data?.eventId?.eventName : ""}
            </span>
            <span className="cart-venue">
              {data?.eventId ? data?.eventId?.starttime : ""},{" "}
              {data?.eventId ? ChangeDataFormat(data?.eventId?.date) : ""},{" "}
              {data?.eventId ? data?.eventId?.location : ""}
            </span>
          </>
        )}

        <div className="cart-math">
          {loading ? (
            <Skeleton height={20} width={100} />
          ) : (
            <span className="total-price">
              Price:
              <span className="price">
                ₹ {data?.eventId ? data?.eventId?.price : ""}
              </span>
            </span>
          )}
          <div className="cart-number-box">
            {loading || isUpdating ? (
              <Skeleton height={25} width={90} />
            ) : (
              <>
                <div className="box" onClick={() => countchange(data, "dec")}>
                  <FaMinus style={{ color: "#fff" }} />
                </div>
                <div className="box">
                  <span className="number">{data?.ticketCount}</span>
                </div>
                <div className="box" onClick={() => countchange(data, "inc")}>
                  <FaPlus style={{ color: "#fff" }} />
                </div>
              </>
            )}
          </div>
          {loading ? (
            <Skeleton height={24} width={24} />
          ) : (
            <MdDelete
              color="red"
              style={{ width: 24, height: 24, cursor: "pointer" }}
              onClick={() => onDelete(data)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CartCard;
