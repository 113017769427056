import React from "react";
import { FaUserCircle, FaShoppingCart } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserDesktopPopup = ({ show, onClose = () => {} }) => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("isAdmin")==="true";

  const handleLogout = () => {
    localStorage.clear();
    navigate("/signin");
    toast.success("Logout successful");
  };
  if (!show) {
    return null;
  }
  return (
    <div className="user-popup-box" onMouseLeave={onClose}>
      <div className="box" onClick={() => navigate("/cart")}>
        <FaShoppingCart size={18}  />
        <span className="text">Your Cart </span>
      </div>
      <div className="box" onClick={() => navigate("/orders")}>
        <FaShoppingCart size={18} />
        <span className="text">Your Orders </span>
      </div>
      <div className="box" onClick={() => navigate("/payment/transitation")}>
        <FaShoppingCart size={18} />
        <span className="text">Your Transacation </span>
      </div>
    { isAdmin && 
    <>
     <span> Admin Dashboard</span>
      <div className="box" onClick={() => navigate("/admin/users")}>
        <FaShoppingCart size={18} />
        <span className="text">All  Users </span>
      </div> <div className="box" onClick={() => navigate("/admin/orders")}>
        <FaShoppingCart size={18} />
        <span className="text">All Orders </span>
      </div> <div className="box" onClick={() => navigate("admin/bookings")}>
        <FaShoppingCart size={18} />
        <span className="text">All Bookings </span>
      </div> <div className="box" onClick={() => navigate("admin/transaction")}>
        <FaShoppingCart size={18} />
        <span className="text">All Transaction </span>
      </div>
      </>}

      <div className="box" onClick={() => handleLogout()}>
        <FaUserCircle size={18} />
        <span className="text">Logout </span>
      </div>
    </div>
  );
};

export default UserDesktopPopup;
