import React, { useEffect, useRef } from "react";
import { GsapAnimation } from "../../utils/animation/Animation";

const ProjectCard = ({ data }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      GsapAnimation("popOut", cardRef.current, { duration: 1 });
    }
  }, []);

  const handleMouseEnter = () => {
    if (cardRef.current) {
      cardRef.current.scrollTop = cardRef.current.scrollHeight;
    }
  };

  const handleMouseLeave = () => {
    if (cardRef.current) {
      cardRef.current.scrollTop = 0; // Reset to the top when hover ends
    }
  };

  return (
    <div className="project-card-wrapper" >
      <img
        src={data.image}
        className="project-card"
        loading="lazy"
        alt="project"
      />
    </div>
  );
};

export default ProjectCard;
