import React from "react";
import Table from "../../component/Table.js";
import InfoCard from "../../component/cards/InfoCard.js";
import BarGraph from "../../component/BarGraph.js";
import Chart from "../../component/Chart.js";

const CommonDashboard = ({ curPageName = "", data = {}, type = "" }) => {

  const cardMapping = [
    { key: "today", display: "Today" },
    { key: "thisWeek", display: "This Week" },
    { key: "thisMonth", display: "This Month" },
    { key: "total", display: "Total" },
  ];

  return (
    <div className="admin-dashboard-wrapper">
      <h2>{curPageName} Dashboard</h2>
      <div className="info-card-container">
        {cardMapping.map((item, index) => (
          <InfoCard
            key={index}
            index={index}
            type={type}
            dataKey={item.display}
            dataValue={data?.cardData?.[item.key] || 0}
          />
        ))}
      </div>
<div className="graph-container">
  <div className="graph"> 
      <BarGraph bardata={data?.barData || {}} />
      </div>
      <div className="graph"> 

      <Chart chartdata={data?.graphData || {}} />
      </div>

      </div>
      <Table data={data?.tableData || []} type={type}/>
    </div>
  );
};

export default CommonDashboard;
