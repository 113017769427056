import React from "react";
import { ourService } from "../../data";
import { useNavigate } from "react-router-dom";

const ServiceModal = ({onClose=()=>{}  }) => {
  const navigate = useNavigate();

  const handleServiceClick = (service) => {
    const serviceSlug = service.toLowerCase().replace(/\s+/g, "-");
    navigate(`/service-details/${serviceSlug}`);
    onClose();
  };

  return (
    <div
      className="service-list-modal"
      onMouseLeave={onClose}
    >
      <div className="service-list">
        {ourService.map((service) => (
          <li
            key={service}
            onClick={() => handleServiceClick(service)}
            className="service-item"
          >
            {service}
          </li>
        ))}
      </div>
    </div>
  );
};

export default ServiceModal;
