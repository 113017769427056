import React, { useState } from "react";
import Input from "../../component/Input";
import { registerUser } from "../../api/Function";
import Loader from "../../component/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Signup = () => {
    const[loading,setIsLoading]=useState(false)
    const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    password: "",
    address: "",
    age: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    password: "",
    address: "",
    age: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (formData.mobile.length !== 10) {
      newErrors.mobile = "Mobile number must be 10 digits.";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required.";
    }
    if (!formData.address.trim()) {
      newErrors.address = "Address is required.";
    }
    if (!formData.age || isNaN(formData.age) || formData.age <= 0) {
      newErrors.age = "Age must be a valid number greater than 0.";
    }

    return newErrors;
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true)
      try {
        const res = await registerUser(formData);
         if (res.status === 201) {
                  localStorage.setItem("authToken", res.data.token);
                  localStorage.setItem("userID", res.data.user._id);
                  localStorage.setItem("userName", res.data.user.name);
                  localStorage.setItem("userAddress", res.data.user.address);
                  localStorage.setItem("isAdmin", res.data.user.isAdmin);
        
                  toast.success("welcome to KARIOX");
                  navigate("/");
                }
      } catch (error) {
        console.error("Signup failed:", error);
      }
      finally{
        setIsLoading(false)
      }
    }
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSignup}>
        <h2>Sign Up</h2>

        <div className="form-group">
          <span>Name</span>
          <Input
            type="text"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            name="name"
            id="name"
            required
            errorText={errors.name}
          />
        </div>

        <div className="form-group">
          <span>Mobile Number</span>
          <Input
            type="text"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Enter your mobile number"
            name="mobile"
            id="mobile"
            required
            errorText={errors.mobile}
          />
        </div>

        <div className="form-group">
          <span>Password</span>
          <Input
            type="text"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            name="password"
            id="password"
            required
            errorText={errors.password}
          />
        </div>

        <div className="form-group">
          <span>Address</span>
          <Input
            type="text"
            value={formData.address}
            onChange={handleChange}
            placeholder="Enter your address"
            name="address"
            id="address"
            required
            errorText={errors.address}
          />
        </div>

        <div className="form-group">
          <span>Age</span>
          <Input
            type="number"
            value={formData.age}
            onChange={handleChange}
            placeholder="Enter your age"
            name="age"
            id="age"
            required
            errorText={errors.age}
          />
        </div>

        <button type="submit" className="signup-button">
          Sign Up
        </button>
      </form>
      <span> already have account <span onClick={()=>{navigate("/signin")
}} > signin here</span></span>

      <Loader show={loading}/>

    </div>
  );
};

export default Signup;
