import React, { useEffect, useState } from "react";
import { whatsapp } from "../assets/image";
import { phoneNumber } from "../data";
import { BiSolidPhoneCall } from "react-icons/bi";
import ContactModal from "./modal/ContactModal";

const ScreenModal = () => {
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [showmodal, setshowmodal] = useState(false);

  const handleCloseModal = () => {
    setshowmodal(false);
  };
  const handleshow = () => setIsChatVisible(true);
  useEffect(() => {
    setTimeout(handleshow, 1500);
  }, []);
  const handlechat = () => {
    try {
      const encodedMessage = encodeURIComponent(
        "Hello! I am interested in Digital Marketing services. could you tell me more about it"
      );
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error("Error submitting message:", error);
      alert("Failed to send message. Please try again.");
    }
  };
  return (
    <div className="screen-modal-wrapper">
      <div
        className={`whatsapp-wrapper ${isChatVisible ? "visible" : ""}`}
        onClick={handlechat}
      >
        <img src={whatsapp} className="chat" />
      </div>
      <div className={`call-wrapper ${isChatVisible ? "visible" : ""}`}>
        <a href="tel: 7307167094​">
          <BiSolidPhoneCall style={{ color: "white", width: 24, height: 24 }} />
        </a>
      </div>

      <div className="appointment-book" onClick={() => setshowmodal(true)}>
        <span> Contact Us </span>
      </div>
      {showmodal && <ContactModal onClose={handleCloseModal} />}
    </div>
  );
};

export default ScreenModal;
