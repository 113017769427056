import React from "react";
import AllService from "../service/component/AllService";
import Service from "./component/Service";
import Contact from "../contact";
import CustomerReview from "./component/CustomerReview";
import Projects from "../about/Projects";
import Ongoing from "./component/Ongoing";
import WorkedComapny from "./component/WorkedComapny";

const Home = () => {
  return (
    <div className="home-container">
      <Service />
      <Ongoing />
      <WorkedComapny />

      <AllService />
      <Projects />
      <CustomerReview />
      <Contact />
    </div>
  );
};

export default Home;
