import React from 'react'

const PaymentButton = ({OnClick=()=>{},pay="2",disable=false}) => {
  return (
<button class="CartBtn" onClick={OnClick} style={{width:'75%'}} disabled={disable}>
 
  <p class="text">  Proceed To Pay ₹ {pay}</p>
</button>  )
}

export default PaymentButton