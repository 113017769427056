import React, { useState } from "react";
import { FaUserCircle, FaShoppingCart } from "react-icons/fa";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ourService } from "../data";

const MobileSidebar = ({ onClose = () => {} }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("isAdmin")==="true";
  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  const handleServiceClick = (service) => {
    const serviceSlug = service.toLowerCase().replace(/\s+/g, "-");
    navigate(`/service-details/${serviceSlug}`);
    onClose();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
    const handleLogout = () => {
      localStorage.clear();
      navigate("/signin");
    };
  return (
    <div className="mobile-container" onClick={onClose}>
      <div
        className="mobile-menu-box active"
        onClick={(e) => e.stopPropagation()}
      >
        <nav className="menu-content">
          <ul>
            <li onClick={() => handleNavigation("/")}>HOME</li>
            <li onClick={() => handleNavigation("/about")}>ABOUT</li>
            <li>
              <div className="dropdown-header" onClick={toggleDropdown}>
                <span>SERVICES</span>
                <MdOutlineArrowDropDown
                  size={24}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {isDropdownOpen && (
                <div className="service-list">
                  {ourService.map((service) => (
                    <li
                      key={service}
                      onClick={() => handleServiceClick(service)}
                      className="service-item"
                    >
                      {service}
                    </li>
                  ))}
                </div>
              )}
            </li>
            {/* <li onClick={() => handleNavigation("/product")}>PRODUCT</li> */}
            {/* <li onClick={() => handleNavigation("/event")}>EVENTS</li> */}
            <li onClick={() => handleNavigation("/work")}>WORK</li>
            {/* <li onClick={() => handleNavigation("/cart")}>YOUR CART</li>
            <li onClick={() => handleNavigation("/orders")}>YOUR ORDERS</li>
            <li onClick={() => handleNavigation("/payment/transitation")}>YOUR TRANSACTION</li>
            <li onClick={() => handleLogout()}>Logout</li> */}


            {isAdmin ? <> 
              {/* <span className="admin-text"> Admin Dashboard</span>
              <li onClick={() => handleNavigation("/admin/users")}>All Users</li>
            <li onClick={() => handleNavigation("/admin/orders")}>All Orders</li>
            <li onClick={() => handleNavigation("/admin/bookings")}>Events Booking</li>
            <li onClick={() => handleNavigation("/admin/transaction")}>All Transaction</li> */}

            </>
            :<> </>
            }
            
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileSidebar;
