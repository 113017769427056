import React from "react";
import { quality } from "../../assets/image";

const InfoCard = ({ type, dataKey, dataValue, index }) => {
  const colors = ["#d5efd5", "#f9dfe4", "#d5d5f4", "rgb(251 239 218)"];

  return (
    <div
      className="info-card"
      style={{ backgroundColor: colors[index % colors.length] }}
    >
      <div className="heading">
        <img src={quality} alt="icon" className="icons" loading="lazy" />
        <span className="head">
          {dataKey} {type} 
        </span>
      </div>
      <span className="info">{dataValue}</span> 
    </div>
  );
};

export default InfoCard;
