import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const Chart = ({chartdata}) => {
  if (!chartdata || !chartdata.labels || !chartdata.datasets?.[0]?.data) {
    console.error('Invalid chartdata structure:', chartdata);
    return <div>No valid data to display</div>;
  }
  const data = {
    labels:chartdata?.labels||[],
    datasets: [
      {
        label: 'Sales',
        data:chartdata?.datasets[0]?.data||[],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true, 
    maintainAspectRatio: false, 
    plugins: {
      legend: {
        display: true, 
        position: 'top', 
      },
      tooltip: {
        enabled: true, 
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        beginAtZero: true, 
        grid: {
          display: true, 
        },
      },
    },
  };

  return (
    <div className="chart-container" style={{ width: '100%', height: '400px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default Chart;