import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import RouteComponent from "./routes/RouteComponent";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import ScreenModal from "./component/ScreenModal";
function App() {
  return (
    <div className="main">
      <BrowserRouter>
        <RouteComponent />
        <ToastContainer
          rtl={false}
          closeOnClick
          theme="colored"
          hideProgressBar
          autoClose={1500}
          transition={Slide}
          closeButton={false}
          newestOnTop={false}
          pauseOnFocusLoss={false}
        />
      </BrowserRouter>

      <ScreenModal />
    </div>
  );
}

export default App;
