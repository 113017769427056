import makeApiCall from "./BaseApi";

const { REACT_APP_BASE_URL } = process.env;

const loginUser = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/users/login`;

  return await makeApiCall("post", url, data, false);
};
const registerUser = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/users/register`;

  return await makeApiCall("post", url, data, false);
};
const eventCreate = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/events/create`;

  return await makeApiCall("post", url, data);
};
const getAllevents = async () => {
  const url = `${REACT_APP_BASE_URL}api/events/`;

  return await makeApiCall("get", url);
};

const deleteEvent = async (id) => {
  const url = `${REACT_APP_BASE_URL}api/events/delete/${id}`;

  return await makeApiCall("delete", url);
};
const makePaymentOrder = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/payment/order/`;

  return await makeApiCall("post", url, data);
};
const verifyPayment = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/payment/verify/`;

  return await makeApiCall("post", url, data);
};

const getAllCartsItem = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/cart/`;
  return await makeApiCall("post", url, data);
};
const getAllbooking = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/bookings/`;

  return await makeApiCall("post", url, data);
};
const addTocart = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/cart/add/`;

  return await makeApiCall("post", url, data);
};
const removeCartItem = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/cart/remove/`;

  return await makeApiCall("post", url, data);
};
const updateCartItem = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/cart/update/`;

  return await makeApiCall("post", url, data);
};
const getAllUser = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/admin/getalluser/`;
  return await makeApiCall("post", url, data);
};
const getAllOrders = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/admin/getallorders/`;
  return await makeApiCall("post", url, data);
};
const getAllEventBookings = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/admin/getalleventbookings/`;
  return await makeApiCall("post", url, data);
};
const getAllTransaction = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/admin/getalltransaction/`;
  return await makeApiCall("post", url, data);
};

const getAllProduct = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/product/`;
  return await makeApiCall("get", url, data);
};
const createProduct = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/product/create`;
  return await makeApiCall("post", url, data);
};
const addProductreview = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/product/addreview`;
  return await makeApiCall("post", url, data);
};
const updateProductreview = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/product/updatereview`;
  return await makeApiCall("post", url, data);
};
const deleteProductreview = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/product/deletereview`;
  return await makeApiCall("post", url, data);
};
const getProductreview = async (data) => {
  const url = `${REACT_APP_BASE_URL}api/product/getproductreview`;
  return await makeApiCall("post", url, data);
};
export {
  loginUser,
  registerUser,
  eventCreate,
  getAllevents,
  deleteEvent,
  makePaymentOrder,
  verifyPayment,
  getAllCartsItem,
  addTocart,
  removeCartItem,
  updateCartItem,
  getAllbooking,
  getAllUser,
  getAllOrders,
  getAllEventBookings,
  getAllTransaction,
  getAllProduct,
  createProduct,
  addProductreview,
  updateProductreview,
  deleteProductreview,
  getProductreview

};
