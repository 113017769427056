import {
  innovation,
  quality,
  unik,
  plan,
  projecttechnogfull,
  projectsasilk,
  projectcarwell,
  compLogo1,
  compLogo2,
  compLogo4,
  compLogo5,
  compLogo6,
  compLogo7,
  compLogo8,
  compLogo9,
  compLogo10,
  compLogo11,
  compLogo12,
  compLogo13,
  compLogo14,
  compLogo15,
  compLogo16,
  compLogo17,
  compLogo18,
  Virendra,
  Abhishek
} from "../src/assets/image";

export const sociallogo = [
  {
    url: "https://www.instagram.com/official_kariox"
  },
  {
    url: "https://www.instagram.com/official_kariox"
  },
  {
    url: "https://www.youtube.com/@Karioxgamer"
  }
];
export const googlejoinform =
  "https://docs.google.com/forms/d/1YfekCqD7sNQhhFrVabhyAN0_7R-XgDzaDyZzItYgxQY/edit";

export const ServiceLocation = [
  "Boosting business growth through digital marketing ",
  " || ",
  "  Call : +91 7307167094​ ",
  " || ",
  "  Office address : Lanka Road, Varansi, Uttar Pradesh",
  " || ",
  " email :   karioxvaranasi@gmail.com"
];
export const ourService = [
  "Digital Marketing",
  "Website Development",
  "Mobile Application",
  "Social Media Marketing",
  "Influencer Marketing",
  "Event Planner",
  "Content Marketing",
  "Whatsapp Marketing",
  "Drone Shoot",
  "Email Marketing",
  "Premium Software Available",
  "All Legal Registration",
  "OutDoor Advertising",
  "Videography",
  "Photography",
  "Photo editing",
  "Video editing",
  "Voice Over Service ",
  "Lead Genration",
  "Video Production Marketing",
  "Graphic Designing",
  "Google My Business ",
  "Google Ads",
  "Meta Ads",
  "SEO",
  "Packing Solutions",
  "Printing Service"
];

export const CompanyPage = ["Home", "About ", "Service", "Work", "Events"];

export const CurrentOffers = [
  "20% off on all Digital Marketing services",
  "15% off on Website Development ",
  "10% off on Social Media Marketing packages",
  "25% off on Content Marketing services",
  "30% off on Video Production Marketing",
  "20% off on all Graphic Designing packages",
  "15% off on SEO services"
];

export const phoneNumber = "91 7307167094";

export const EventOffer = [
  "Book Hot Air Balloon Trip now",
  "Book Garba Ticket now",
  "Join Christmas Party this December",
  "Get your New Year Party Ticket now",
  "Reserve your Concert Tickets today"
];

export const customSelctStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "medium",
    width: "360px",
    height: "50px",
    margin: "0.5rem",
    padding: "0.5rem ",
    color: "white",
    border: "0.5px ridge #0ef",
    borderRadius: "5px",
    background:
      "linear-gradient(180deg, rgb(14, 13, 16), rgb(23, 1, 54), rgb(14, 13, 16))"
  }),
  menu: (provided) => ({
    ...provided,
    background:
      "linear-gradient(180deg, rgb(14, 13, 16), rgb(23, 1, 54), rgb(14, 13, 16))",
    color: "white"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white"
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white"
  })
};

export const AllServiceData = [
  {
    name: "Digital Marketing",
    about:
      "Comprehensive digital marketing solutions to boost your online presence.",
    pricestart: "₹ 2000",
    url: "https://i.pinimg.com/originals/1d/d9/06/1dd906816dec2a2d09cb5c8a825bbb69.gif"
  },
  {
    name: "Website Development",
    about: "Professional website development services for your business.",
    pricestart: "₹ 5000",
    url: "https://cdn.dribbble.com/users/1233499/screenshots/3850691/web-development.gif"
  },
  {
    name: "Social Media Marketing",
    about: "Social media strategies to enhance engagement and reach.",
    pricestart: "₹ 3000",
    url: "https://cdn.dribbble.com/users/2918596/screenshots/9246326/media/d2a065d587a99f32128f223b2d8e7d69.gif"
  },
  {
    name: "Content Marketing",
    about:
      "Creating and distributing valuable content to attract your audience.",
    pricestart: "₹ 2500",
    url: "https://images.squarespace-cdn.com/content/v1/5e59eefe0e4624584d852c64/1694486671618-6CXU9VP1Z67MTOBUF7JR/giphy.gif"
  },
  {
    name: "Event Planner",
    about: "Seamless event planning for unforgettable experiences.",
    url: "https://i.pinimg.com/originals/8b/04/c9/8b04c9405a307df013312b2057e791fe.gif"
  },
  {
    name: "Video Production Marketing",
    about: "High-quality video content production for marketing.",
    pricestart: "₹ 7000",
    url: "https://cdn.dribbble.com/users/1913706/screenshots/7037560/video-marketing-guide_final-min.gif"
  },
  {
    name: "Graphic Designing",
    about: "Creative graphic design solutions for your brand.",
    pricestart: "₹ 1500",
    url: "https://miro.medium.com/v2/resize:fit:1400/1*MptjiGxY9vSRW4ZC9S7Isw.gif"
  },
  {
    name: "Google My Business",
    about: "Optimize your Google My Business listing for better visibility.",
    pricestart: "₹ 1000",
    url: "https://i.pinimg.com/originals/64/02/d3/6402d301d36e79fddd81bcbcb0a53958.gif"
  },
  {
    name: "SEO",
    about: "Search Engine Optimization to improve your website's ranking.",
    pricestart: "₹ 4000",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrtiIidNU1BWuaqN5XlsuseceDaJag-v2guA&s"
  },
  {
    name: "Printing Service",
    about: "High-quality printing for all your business and personal needs.",
    url: "https://mir-s3-cdn-cf.behance.net/project_modules/disp/1b0c3b36434837.571cb1640cdf6.gif"
  }
];

export const CustomerReviewCardata = [
  {
    name: "Anjali Verma",
    address: "Krishna Gym Center, Varanasi",
    servicetype: "Digital Marketing",
    review: "Bahut accha kaam tha, services shandar hain!",
    rating: "5"
  },
  {
    name: "Ravi Singh",
    address: "Aarogya Hospital, Varanasi",
    servicetype: "Website Development",
    review: "The website development was superb and met all our needs.",
    rating: "4"
  },
  {
    name: "Meera Sharma",
    address: "Sharma Kirana Shop, Varanasi",
    servicetype: "Social Media Marketing",
    review: "Social media campaigns brought great engagement!",
    rating: "5"
  },

  {
    name: "Amit Trivedi",
    address: "Aashirwad Medical Center, Varanasi",
    servicetype: "SEO",
    review: "Website ranking mein bahut sudhar hua unke SEO se.",
    rating: "5"
  },
  {
    name: "Kiran Mehta",
    address: "Anand Bakery, Varanasi",
    servicetype: "Digital Marketing",
    review: "Hamara business visibility mein badhotri hui!",
    rating: "5"
  },
  {
    name: "Vijay Bhatt",
    address: "Health Hub Clinic, Varanasi",
    servicetype: "Website Development",
    review: "Website bahut akarshak aur user-friendly hai.",
    rating: "4"
  },

  {
    name: "Manish Arora",
    address: "Wellness Medical Store, Varanasi",
    servicetype: "SEO",
    review: "SEO services ne hamare traffic aur conversions improve kiye.",
    rating: "5"
  }
];

export const WorkStrategyCarddata = [
  {
    image: innovation,
    title: "Innovative Team",
    about:
      "We are creative Geeks with continues focus and dedication towards our goal meeting every Challenge as opportunity."
  },
  {
    image: quality,
    title: "Quality Standards",
    about:
      "We Deliver web solutions which are build by predefined high Quality standard reflecting in out client satisfaction."
  },
  {
    image: unik,
    title: "Unique Approach",
    about:
      "All of our works are crafted and motivated by uniquely creative approach & never before seen Designs."
  },
  {
    image: plan,
    title: "Strategic Consulting",
    about:
      "Our Experts Provide in technical and business strategic  and solutions ensuring our clients success."
  }
];

export const ProjectDemoImage = [
  {
    image: projecttechnogfull
  },
  {
    image: projectsasilk
  },
  {
    image: projectcarwell
  }
];

export const OngoingData = [
  {
    text: "Ongoing Projects",
    num: "30"
  },
  {
    text: "Happy Customer",
    num: "83"
  },
  {
    text: "Team Members",
    num: "20"
  },
  {
    text: "Succesfull Projects",
    num: "111"
  }
];

export const TeamData = [
  {
    name: "Adv Sameeksha  Singh",
    job: "Legal Advisor",
    url: "https://img.freepik.com/premium-photo/portrait-young-indian-female-lawyer-smiling-happy-her-workplace-office-indian-lawyer-technologist-professional-face-female-lawyer-legal-consultant-law-firm_785351-3584.jpg"
  },
  {
    name: "Abhishek Rawat",
    job: "Business Development  Head",
    url: Abhishek
  },

  // {
  //   name: "Aakanksha Singh",
  //   job: "Website Developer",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  // {
  //   name: "Aditya Kunwar Singh",
  //   job: "Graphic  Designer",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  // {
  //   name: "Katyayani Keshri",
  //   job: "Voice  Artist",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  {
    name: "Virendra Patel",
    job: "Social Media Marketing Manager",
    url: Virendra
  }
  // {
  //   name: "Aman Singh",
  //   job: "Video Editor",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  // {
  //   name: "Aman Kumar",
  //   job: "Ai Digital Marketing Specialist",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  // {
  //   name: "Vikas Kumar",
  //   job: "Video Editor",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  // {
  //   name: "Raushan Tiwari",
  //   job: "Digital Marketing Specialist",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
  // {
  //   name: "Neha Dubey",
  //   job: "Email Marketing Intern",
  //   url: "https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg",
  // },
];

export const companyLogo = [
  {
    image: compLogo1
  },
  {
    image: compLogo2
  },

  {
    image: compLogo8
  },
  {
    image: compLogo13
  },
  {
    image: compLogo5
  },
  {
    image: compLogo10
  },
  {
    image: compLogo6
  },
  {
    image: compLogo7
  },
  {
    image: compLogo4
  },

  {
    image: compLogo9
  },

  {
    image: compLogo11
  },
  {
    image: compLogo12
  },

  {
    image: compLogo14
  },
  {
    image: compLogo15
  },
  {
    image: compLogo16
  },
  {
    image: compLogo17
  },
  {
    image: compLogo18
  }
];
