import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addTocart, getAllProduct } from "../../api/Function";
import { useNavigate } from "react-router-dom";
import ProductCard from "../../component/cards/ProductCard";
import Loader from "../../component/Loader"
const Products = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [productData, setProductData] = useState([]);
  const navigate=useNavigate();
  const userId = localStorage.getItem("userID");
  const isAdmin = localStorage.getItem("isAdmin")==="true";
  const handleCreate = () => {
    navigate("/product/create");
  };
  const handlegetAllProduct = async () => {
    setIsLoading(true);
    try {
      const res = await getAllProduct();
      if(res.status===200){
        setProductData(res?.data)
      }
      else{
        toast.error("please try again")
      }
    } catch (err) {
      console.log(err, "Product Error");
      toast.error("oops something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
const handleAddtocart=async(data,quantity)=>{
  setIsAdding(true);
  let payload = {
    userId,
    productId: data?._id,
    quantity: quantity,
  };
  try {
   const result = await addTocart(payload);
    if (result.status === 200) {
      toast.success("Product added in cart successfully");
      //navigate("/cart");
    } else {
    }
  } catch (error) {
    console.log(error, "error");
  } finally {
    setIsAdding(false);
  }
}
  useEffect(() => {
    handlegetAllProduct();
  }, []);

  return <div className="product-page-wrapper">
    {isAdmin &&  <button className="create-event" onClick={() => handleCreate()}>
        Add Product
      </button>}
      <div className="product-container"> 
      {productData && productData?.map((item,index)=>{
        return  <ProductCard key={index} data={item} onCartClick={handleAddtocart}/>

      })}
      </div>
      <Loader show={isAdding}/>
  </div>;
};

export default Products;
