import React from 'react'
import NumberCard from '../../../component/cards/NumberCard'
import { OngoingData } from '../../../data'

const Ongoing = () => {
  return (
    <div className='ongoing-wrapper'>
        {OngoingData ?.map((item)=>{
            return <NumberCard data={item}/>
        }) }
    </div>
  )
}

export default Ongoing