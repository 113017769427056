import React from "react";
import { useNavigate } from "react-router-dom";
import { fullbg } from "../../assets/image";
const ServiceCard = ({ data }) => {
  const navigate = useNavigate();
  const handleClick = (service) => {
    const serviceSlug = service.toLowerCase().replace(/\s+/g, "-");
    navigate(`/service-details/${serviceSlug}`);  }
  return (
    <div className="servicecard">
      <div class="card-inside">
        <img src={data?.url} loading="lazy" alt="icon" className="image"  />
        <div class="card__content">
          {/* <p class="card__title"> Price Start {data?.pricestart} </p> */}
          <p class="card__description">
          {data?.about}
          </p>
        </div>
      </div>
      <span className="service-name" onClick={()=>handleClick(data?.name)}> {data?.name} </span>

    </div>
  );
};

export default ServiceCard;
