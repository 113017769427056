import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CommonSlider = ({
  heading = "",
  data = [],
  Component,
  cardWidth = 350,
  AutoTime = 3000,
  autoplay = true,
  centerMode = true,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newSlidesToShow =  Math.floor(screenWidth / cardWidth);
      setSlidesToShow(newSlidesToShow);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [cardWidth, centerMode]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: AutoTime,
    lazyLoad: "ondemand",
    centerMode: centerMode,
  };

  return (
    <div className="slider-container">
      <span className="heading">{heading}</span>
      <Slider {...settings}>
        {data?.map((item, key) => (
          <div key={key} className="slide">
            <Component data={item} index={key} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CommonSlider;
