import React, { useState } from "react";
import { makePayment, verifyPayment } from "../../api/Function";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EventDetails = () => {
  const [paymantAmount, setPaymentAmount] = useState(77);
  const { id } = useParams();

  return (
    <div>
      EventDetails
      <button>Pay</button>
    </div>
  );
};

export default EventDetails;
