import React from "react";
import { FaUserSecret } from "react-icons/fa6";
import { blankstar,activestar } from "../../assets/image";

const CustomerReviewCard = ({data}) => {
    const renderStars = (rating) => {
        const totalStars = 5;
        const activeStars = Math.floor(rating);
        const inactiveStars = totalStars - activeStars;
    
        return (
          <>
            {[...Array(activeStars)].map((_, i) => (
              <img
                key={i}
                src={activestar}
                alt="active star"
                width={18}
                height={18}
              />
            ))}
            {[...Array(inactiveStars)].map((_, i) => (
              <img
                key={i}
                src={blankstar}
                alt="inactive star"
                width={18}
                height={18}
              />
            ))}
          </>
        );
      };
  return (
    <div className="review-card-container">
      <div className="user-box">
        <div className="user-icon">
          <FaUserSecret />
        </div>

        <div className="user-info">
          <span className="name">{data?.name}</span>
          <span className="address">{data?.address}</span>
        </div>
      </div>

      <div className="content">
      <div className='rating'>{renderStars(data?.rating)}</div>

        <span className="service-type">{data?.servicetype} </span>
        <span className="description">{data?.review}</span>
      </div>
    </div>
  );
};

export default CustomerReviewCard;
