import React from 'react'
import AllService from './component/AllService'

const Service = () => {
  return (
    <div>Service INFO
        <AllService/>
    </div>
  )
}

export default Service