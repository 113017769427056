import React, { useState, useEffect } from "react";
import CommonDashboard from "./CommonDashboard";
import { useLocation } from "react-router-dom";
import {
  getAllEventBookings,
  getAllOrders,
  getAllTransaction,
  getAllUser,
} from "../../api/Function";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const Admin = () => {
  const [curData, setCurData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const userId = localStorage.getItem("userID");
  const payload = { adminID: userId };
  const name =
    path.split("/").pop().charAt(0).toUpperCase() +
    path.split("/").pop().slice(1);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response = null;

      const endpoint = path.split("/").pop();

      switch (endpoint) {
        case "transaction":
          response = await getAllTransaction(payload);
          break;
        case "bookings":
          response = await getAllEventBookings(payload);
          break;
        case "orders":
          response = await getAllOrders(payload);
          break;
        case "users":
          response = await getAllUser(payload);
          break;
        default:
          console.error("Invalid endpoint");
          return;
      }
      if (response?.data?.success) {
        setCurData(response?.data?.data || {});
      } else {
        toast.error("something went wrong !");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [path]);
  return (
    <div>
      {isLoading ? (
        <div className="loader-admin">
          <div className="card">
            {[...Array(4)].map((_, key) => {
              return <Skeleton width={200} height={100} key={key} />;
            })}
          </div>
            <Skeleton height={350} />
            <Skeleton height={350} />
            <Skeleton height={450} />
        </div>
      ) : (
         <CommonDashboard curPageName={name} data={curData} type={name} />
      )}
    </div>
  );
};

export default Admin;
