import React from "react";
import ProjectCard from "../../component/cards/ProjectCard";
import { ProjectDemoImage } from "../../data";

const Projects = () => {
  return (
    <div className="conatiner">
      <span className="heading">
        See Our <span className="hero">Works </span>
      </span>
      <div className="feature-projects">
        <div className="project-container">
          {ProjectDemoImage.map((item, key) => {
            return <ProjectCard data={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
