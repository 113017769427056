export const serviceDetails = {
  "digital-marketing": {
    name: "Digital Marketing",
    description: "Learn more about our Digital Marketing services.",
    features: [
      "SEO Optimization",
      "Pay-Per-Click Advertising",
      "Email Campaigns",
      "Analytics and Reporting",
    ],
    faq: [
      {
        question: "What is included in your SEO Optimization service?",
        answer:
          "Our SEO Optimization service includes keyword research, on-page and off-page optimization, technical SEO improvements, and regular performance tracking to help improve your website's search engine rankings.",
      },
      {
        question: "How does Pay-Per-Click Advertising work?",
        answer:
          "Pay-Per-Click (PPC) Advertising allows you to display ads on search engines or social media platforms, and you only pay when someone clicks on your ad. We manage ad creation, targeting, and optimization to maximize your ROI.",
      },
      {
        question: "What types of email campaigns do you create?",
        answer:
          "We create various email campaigns, including promotional emails, newsletters, automated responses, and personalized messages to engage your audience and drive conversions.",
      },
      {
        question: "How do you provide analytics and reporting?",
        answer:
          "We provide detailed analytics and reporting by tracking key metrics like website traffic, conversion rates, campaign performance, and user behavior. Reports are tailored to help you make informed decisions.",
      },
    ],
  },
  "website-development": {
    name: "Website Development",
    description:
      "Explore our Website Development solutions tailored to meet your specific needs.",
    features: [
      "Build an online store or e-commerce website to sell your products efficiently.",
      "Create an event website for weddings, parties, or corporate events.",
      "Develop a restaurant website to streamline online orders, delivery, and payments.",
      "Design a business website to help manage and grow your enterprise.",
      "Showcase your art with an online portfolio website.",
      "Craft a resume website to grab the attention of potential employers.",
      "Build a fitness website to attract and book new clients.",
      "Display and sell prints through a photography website.",
      "Start a blog to share your knowledge and insights with the world.",
    ],
    faq: [
      {
        question: "What types of websites can you develop?",
        answer:
          "We can develop various websites, including e-commerce stores, event websites, business websites, portfolios, blogs, and more, based on your specific requirements.",
      },
      {
        question: "Do you offer customization for websites?",
        answer:
          "Yes, all our websites are fully customizable to reflect your brand's identity and meet your unique needs.",
      },
      {
        question: "Are the websites mobile-friendly?",
        answer:
          "Yes, we ensure all our websites are responsive and optimized for mobile, tablet, and desktop devices.",
      },
      {
        question: "Can you integrate payment systems for e-commerce websites?",
        answer:
          "Absolutely! We integrate secure payment gateways to facilitate smooth transactions for your e-commerce platform.",
      },
      {
        question: "Do you provide ongoing maintenance and support?",
        answer:
          "Yes, we offer maintenance and support services to ensure your website stays up-to-date and runs smoothly.",
      },
    ],
  },
  "social-media-marketing": {
    name: "Social Media Marketing",
    description:
      "It is the use of social media platforms to connect with your audience to build your brand, increase sales, and drive website traffic.",
    features: [
      "Social Media Audit",
      "Brand Awareness",
      "Lead Generation",
      "Handling Social Media",
      "Ads Runs",
      "SM Platform Handling",
      "Viral Marketing",
      "Social Media Management",
      "Analyze Your Competitors",
      "Online Reputation",
      "Festival Greeting Creative Posts",
    ],
    faq: [
      {
        question: "What is included in your Social Media Audit service?",
        answer:
          "Our Social Media Audit includes evaluating your current social media presence, identifying strengths and weaknesses, and providing actionable recommendations for improvement.",
      },
      {
        question: "How can Social Media Marketing improve brand awareness?",
        answer:
          "We create targeted campaigns, engaging posts, and ads to increase your visibility on various social media platforms and attract the right audience.",
      },
      {
        question: "Do you manage multiple social media platforms?",
        answer:
          "Yes, we manage all major platforms such as Facebook, Instagram, Twitter, LinkedIn, and more, ensuring a consistent brand presence.",
      },
      {
        question: "What types of ads do you run on social media?",
        answer:
          "We create and manage a variety of ad campaigns, including brand awareness ads, lead generation ads, and retargeting campaigns.",
      },
      {
        question: "How do you measure the success of social media campaigns?",
        answer:
          "We track metrics such as engagement, reach, clicks, conversions, and ROI, providing detailed reports to assess campaign performance.",
      },
    ],
  },
  "content-marketing": {
    name: "Content Marketing",
    description:
      "Our Content Marketing services are designed to engage and inform your target audience, driving traffic and building brand authority. From blogs and whitepapers to infographics and video content, we provide creative strategies to amplify your online presence and support your marketing goals.",
    features: [
      "Blog Writing",
      "Whitepapers",
      "Infographics",
      "Video Content",
      "Guest Posting",
      "Content Writing",
      "Content Creation",
      "SEO Copywriting",
      "Blog Post",
      "Video / Poster Design",
    ],
    faq: [
      {
        question: "What type of content do you create for marketing?",
        answer:
          "We create blogs, whitepapers, infographics, videos, SEO-friendly articles, and engaging social media posts tailored to your audience.",
      },
      {
        question:
          "How does Content Marketing help in building brand authority?",
        answer:
          "Consistently delivering valuable and informative content positions your brand as an industry leader, building trust with your audience.",
      },
      {
        question: "Do you optimize content for SEO?",
        answer:
          "Yes, we ensure all content is optimized with relevant keywords, meta descriptions, and tags to improve search engine rankings.",
      },
      {
        question: "What is included in your Guest Posting service?",
        answer:
          "Our Guest Posting service includes writing and publishing articles on high-authority websites to boost your domain authority and reach.",
      },
      {
        question: "Can you create customized video content for campaigns?",
        answer:
          "Absolutely! We produce engaging video content tailored to your marketing goals, including promotional videos, tutorials, and more.",
      },
    ],
  },
  "video-production-marketing": {
    name: "Video Production Marketing",
    description:
      "Nowadays marketing through video is becoming very popular. Marketing through video has become easy, and video marketing is a good option to increase sales.",
    features: [
      "Video Production",
      "Video Editing",
      "Video Ads Shoot",
      "Script Writing",
      "Thumbnail Creation",
      "Story",
      "Online Reputation",
      "Voice Over (Male/Female)",
    ],
    faq: [
      {
        question: "What is included in your video production service?",
        answer:
          "Our video production service includes planning, filming, and editing to deliver high-quality videos tailored to your marketing goals.",
      },
      {
        question: "Can you create custom scripts for my video ads?",
        answer:
          "Yes, we offer scriptwriting services to ensure your video ads have a compelling and engaging storyline.",
      },
      {
        question: "Do you provide voiceover options for different languages?",
        answer:
          "Yes, we provide professional voiceover services in multiple languages with options for male and female voices.",
      },
      {
        question: "What types of video ads do you shoot?",
        answer:
          "We shoot promotional videos, product demos, explainer videos, and social media ads designed to enhance audience engagement.",
      },
      {
        question: "Can you create customized thumbnails for videos?",
        answer:
          "Absolutely! We create visually appealing and professional thumbnails to make your videos stand out.",
      },
    ],
  },
  "graphic-designing": {
    name: "Graphic Designing",
    description: "Explore our Graphic Designing services.",
    features: [
      "Logo",
      "Banner",
      "Brochures",
      "Magazines",
      "Reports",
      "Certificate",
      "ID Card",
      "Symbol",
      "Menu",
      "Social Media Posters",
      "Business Posters",
      "YouTube Thumbnails",
      "Marketing Poster",
      "Branding Poster",
      "Visiting Card",
    ],
    faq: [
      {
        question: "What types of graphic designs do you offer?",
        answer:
          "We offer a wide range of designs, including logos, banners, brochures, posters, YouTube thumbnails, and more.",
      },
      {
        question: "Can you create a custom logo for my brand?",
        answer:
          "Yes, we specialize in designing unique logos that reflect your brand's identity and values.",
      },
      {
        question: "Do you provide designs for social media marketing?",
        answer:
          "Yes, we create engaging social media posters, business posters, and branding content tailored to your audience.",
      },
      {
        question: "Are your designs optimized for print and digital media?",
        answer:
          "Yes, we ensure our designs are suitable for both print and digital platforms, maintaining quality across mediums.",
      },
      {
        question:
          "Can you design certificates and ID cards for events or organizations?",
        answer:
          "Absolutely! We provide professional designs for certificates, ID cards, and other event-related materials.",
      },
    ],
  },
  "google-my-business-": {
    name: "Google My Business",
    description: "Optimize your presence with Google My Business.",
    features: [
      "Profile Setup",
      "Review Management",
      "Local SEO",
      "Business Insights",
      "GMB Optimization",
      "Listing Verification",
      "Top Ranking Google Listing",
      "Organic Review",
      "Services Plan Optimization",
      "Create a Professional Gallery",
      "Google Maps Integration",
      "Optimization Keyword",
    ],
    faq: [
      {
        question: "What is included in your Google My Business profile setup?",
        answer:
          "Our profile setup service includes creating a professional profile, adding accurate business details, and optimizing it for visibility.",
      },
      {
        question: "How do you manage reviews on Google My Business?",
        answer:
          "We monitor, respond to, and manage customer reviews to enhance your online reputation and build trust with potential clients.",
      },
      {
        question: "Can you optimize my business for local SEO?",
        answer:
          "Yes, we optimize your Google My Business profile with targeted keywords and strategies to improve your local search rankings.",
      },
      {
        question: "What is listing verification, and why is it important?",
        answer:
          "Listing verification ensures your business is legitimate, improves trustworthiness, and helps your profile appear in local searches.",
      },
      {
        question: "How do you integrate Google Maps with my business profile?",
        answer:
          "We add accurate location details and optimize your Google Maps integration to make it easier for customers to find your business.",
      },
    ],
  },

  seo: {
    name: "Search Engine Optimization",
    description:
      "Search Engine Optimization is the process of getting traffic from the free organic or natural search results on search engines.",
    features: [
      "Link Building",
      "Enterprise SEO",
      "Web Analytics",
      "Increase Website Traffic",
      "Worldwide SEO",
      "Local SEO",
      "E-Commerce SEO",
      "Keyword Research & Optimization",
    ],
    faq: [
      {
        question: "What is SEO, and how does it benefit my business?",
        answer:
          "SEO helps improve your website's visibility on search engines, driving organic traffic and increasing your business's online presence.",
      },
      {
        question: "What is the difference between Local SEO and Worldwide SEO?",
        answer:
          "Local SEO focuses on optimizing your website for a specific location, while Worldwide SEO targets a global audience.",
      },
      {
        question: "How long does it take to see results with SEO?",
        answer:
          "SEO results typically take 3-6 months, depending on competition and the strategies implemented.",
      },
      {
        question: "What is Enterprise SEO?",
        answer:
          "Enterprise SEO involves large-scale optimization strategies for extensive websites, often for corporations or large businesses.",
      },
      {
        question: "Do you provide keyword research and optimization?",
        answer:
          "Yes, we identify the best keywords for your business and optimize your content to rank higher on search engines.",
      },
    ],
  },
  "influencer-marketing": {
    name: "Influencer Marketing",
    description:
      "Influencer marketing is a collaboration between popular social media users and brands to promote brands' products or services. These partnerships have been going on informally since the dawn of social media.",
    features: [
      "Local Influencers",
      "Indian Influencers",
      "Foreign Influencers",
    ],
    faq: [
      {
        question: "How do you select influencers for my brand?",
        answer:
          "We analyze your brand's goals, target audience, and niche to identify influencers who align with your values and can maximize reach.",
      },
      {
        question: "What types of influencers do you work with?",
        answer:
          "We collaborate with local, Indian, and international influencers across various niches like fashion, tech, lifestyle, and more.",
      },
      {
        question: "Can influencer marketing increase my sales?",
        answer:
          "Yes, influencer marketing can boost brand awareness, drive traffic, and increase conversions by reaching a targeted audience.",
      },
      {
        question: "Do you handle campaign monitoring and reporting?",
        answer:
          "Yes, we track the performance of influencer campaigns and provide detailed reports to measure ROI.",
      },
      {
        question: "What platforms do you support for influencer marketing?",
        answer:
          "We support platforms like Instagram, YouTube, Facebook, and TikTok for influencer marketing campaigns.",
      },
    ],
  },
  "google-ads": {
    name: "Google Ads",
    description:
      "Google's online advertising program. Through Google Ads, you can create online ads to reach people exactly when they're interested in the products and services that you offer.",
    features: [
      "Shopping Ads",
      "Display Ads",
      "Video Ads",
      "App Ads",
      "Image Ads",
      "Text Ads",
      "Google Local Service Ads",
    ],
    faq: [
      {
        question: "What types of Google Ads do you provide?",
        answer:
          "We provide Shopping Ads, Display Ads, Video Ads, App Ads, Image Ads, Text Ads, and Google Local Service Ads.",
      },
      {
        question: "How does Google Ads improve my business visibility?",
        answer:
          "Google Ads places your business at the top of search results, reaching potential customers actively searching for your products or services.",
      },
      {
        question: "Do you offer campaign management for Google Ads?",
        answer:
          "Yes, we create, manage, and optimize your Google Ads campaigns to maximize ROI.",
      },
      {
        question: "How do you target the right audience with Google Ads?",
        answer:
          "We use detailed audience segmentation, keyword targeting, and location-based strategies to reach the most relevant users.",
      },
      {
        question: "What is the benefit of using Google Local Service Ads?",
        answer:
          "Google Local Service Ads connect your business with local customers looking for your services, increasing leads and conversions.",
      },
    ],
  },
  "meta-ads": {
    name: "Meta Ads",
    description:
      "Meta Ads (ads across Facebook, Messenger, Instagram, or Meta Audience Network) are paid messages from businesses that are written in their voice and help reach the people who matter most to them.",
    features: ["Video Ads", "Slideshow Ads", "Lead Awareness"],
    faq: [
      {
        question: "What are Meta Ads?",
        answer:
          "Meta Ads are advertisements across platforms like Facebook, Messenger, and Instagram, designed to reach a targeted audience effectively.",
      },
      {
        question: "How do Meta Ads help my business?",
        answer:
          "Meta Ads boost brand awareness, generate leads, and drive engagement across popular social media platforms.",
      },
      {
        question: "Do you handle ad design and placement?",
        answer:
          "Yes, we design and strategically place ads to ensure maximum visibility and impact.",
      },
      {
        question: "What is the benefit of Slideshow Ads?",
        answer:
          "Slideshow Ads use lightweight videos to tell a story, offering an engaging way to showcase your brand without high production costs.",
      },
      {
        question: "Can you track the performance of my Meta Ads campaigns?",
        answer:
          "Absolutely! We provide detailed analytics and performance reports for your campaigns.",
      },
    ],
  },
  "drone-shoot": {
    name: "Drone Shoot",
    description:
      "Capture stunning aerial views with our professional drone shooting services.",
    features: [
      "Location Video Shoot",
      "Videography",
      "Wedding Shoot",
      "Pre-Wedding Shoot",
      "Photography",
    ],
    faq: [
      {
        question: "What types of events do you cover with drone shoots?",
        answer:
          "We cover various events like weddings, pre-wedding shoots, corporate events, and promotional shoots with our drone services.",
      },
      {
        question: "What equipment do you use for drone shoots?",
        answer:
          "We use high-quality professional drones equipped with advanced cameras for capturing stunning aerial footage.",
      },
      {
        question: "Can you edit the footage captured during a drone shoot?",
        answer:
          "Yes, we offer video editing services to enhance the captured footage and deliver polished, professional-quality videos.",
      },
      {
        question: "Are your drone services available for commercial projects?",
        answer:
          "Yes, we provide drone shooting services for commercials, real estate, and promotional projects.",
      },
      {
        question: "Do you offer custom packages for drone shooting services?",
        answer:
          "Yes, we provide tailored packages to meet your specific requirements and budget.",
      },
    ],
  },

  videography: {
    name: "Videography",
    description:
      "Capture high-quality videos for various needs, from events to corporate shoots.",
    features: [
      "Reels Shoot",
      "Short Film",
      "Documentary",
      "Wedding Shoot",
      "Pre-Wedding Shoot",
      "Long Video Shoot",
      "Entertainment Shoot",
      "Product Shoot",
      "Announcement Video Shoot",
      "Advertisements Videos Shoot",
      "Property Video Shoot",
      "Social Videos Shoot",
      "Event Videography",
      "Educational Videos",
      "Informational Videos",
      "Tutorial Videos",
      "Corporate Videography",
    ],
    faq: [
      {
        question: "What types of events do you cover for videography?",
        answer:
          "We cover a wide range of events, including weddings, pre-weddings, corporate events, product shoots, and more.",
      },
      {
        question: "Do you provide video editing services?",
        answer:
          "Yes, we offer comprehensive video editing services to ensure your videos are polished and ready for distribution.",
      },
    ],
  },
  photography: {
    name: "Photography",
    description:
      "Capture the perfect moments with our professional photography services. All types of photo shoots available.",
    features: [
      "Food Photography",
      "Wedding Photography",
      "Pre-Wedding Shoot",
      "Event Photography",
      "Street Photography",
      "Sports Photography",
      "Real Estate Photography",
      "Architecture Photography",
      "Digital Photography",
      "Commercial Photography",
      "Portrait Photography",
      "Model Shoot",
      "Medicine Photography",
      "Adventure Photography",
      "Travel Photography",
    ],
    faq: [
      {
        question: "Do you offer photography for special events?",
        answer:
          "Yes, we offer photography services for weddings, corporate events, birthday parties, and other special occasions.",
      },
      {
        question: "Can I request custom photo edits after the shoot?",
        answer:
          "Absolutely! We offer photo editing services to enhance your photos as per your preferences.",
      },
    ],
  },
  "photo-editing": {
    name: "Photo Editing",
    description:
      "Enhance your photos with our professional photo editing services.",
    features: [
      "Exposure Adjustment",
      "Color Correction",
      "Sharpening",
      "Filters and Presets",
      "Noise Reduction",
      "Retouching",
      "Ignoring Composition",
      "Background Replacement",
      "HDR Editing",
      "Special Effects",
      "Lens Correction",
      "Panorama Stitching",
      "Composite Editing",
    ],
    faq: [
      {
        question: "What type of photo edits can you perform?",
        answer:
          "We can perform various edits like exposure adjustment, color correction, background replacement, and much more.",
      },
      {
        question: "Do you offer photo retouching services?",
        answer:
          "Yes, we provide professional photo retouching services to enhance your photos, including skin retouching and blemish removal.",
      },
    ],
  },
  "video-editing": {
    name: "Video Editing",
    description:
      "Professional video editing services for creating high-quality, engaging content.",
    features: [
      "Linear Editing",
      "Non-Linear Editing",
      "Offline Editing",
      "Online Editing",
      "Assembly Editing",
      "Montage Editing",
      "Cutting on Action",
      "Cross-Cutting",
      "Jump Cut",
      "Motion Graphics Editing",
    ],
    faq: [
      {
        question: "Can you edit videos for different platforms?",
        answer:
          "Yes, we can tailor your videos for various platforms like YouTube, Instagram, Facebook, and more.",
      },
      {
        question: "Do you offer motion graphics in video editing?",
        answer:
          "Yes, we provide motion graphics editing, including animated text, logos, and transitions.",
      },
    ],
  },
  "mobile-application": {
    name: "Mobile Application",
    description:
      "Design and develop high-quality mobile applications tailored to your business needs, ensuring seamless user experience and functionality.",
    features: [
      "iOS App Development",
      "Android App Development",
      "Cross-Platform App Development",
      "UI/UX Design",
      "E-commerce App Development",
      "Social Media App Development",
      "Healthcare App Development",
      "Finance & Banking App Development",
      "Education & E-learning Apps",
      "On-Demand Service Apps",
      "Fitness & Wellness Apps",
      "Food Delivery Apps",
      "Booking & Reservation Apps",
      "Enterprise Mobile Solutions",
      "Maintenance & Support",
      "Custom API Development",
      "App Store Optimization",
    ],
    faq: [
      {
        question: "What platforms do you develop mobile applications for?",
        answer:
          "We develop mobile applications for iOS, Android, and cross-platform solutions using technologies like React Native and Flutter.",
      },
      {
        question: "Do you provide app maintenance and updates?",
        answer:
          "Yes, we offer ongoing maintenance and updates to ensure your mobile application runs smoothly and stays up to date with the latest trends and security measures.",
      },
    ],
  },
  "event-planner": {
    name: "Event Planner",
    description:
      "Plan and execute memorable events with our expert event planning services, ensuring a seamless and stress-free experience.",
    features: [
      "Corporate Event Planning",
      "Wedding Planning",
      "Birthday Party Planning",
      "Anniversary Celebrations",
      "Concert & Live Shows",
      "Exhibitions & Trade Shows",
      "Product Launch Events",
      "Charity & Fundraising Events",
      "Award Ceremonies",
      "Fashion Shows",
      "Destination Event Planning",
      "Theme Party Planning",
      "Social Gatherings",
      "Conference & Seminar Planning",
      "Festival Event Planning",
      "Team Building Events",
      "Private & VIP Events",
    ],
    faq: [
      {
        question: "What types of events do you specialize in?",
        answer:
          "We specialize in a wide range of events, including corporate events, weddings, social gatherings, product launches, and more.",
      },
      {
        question: "Do you provide event decoration and catering services?",
        answer:
          "Yes, we offer complete event solutions, including decoration, catering, entertainment, and logistics management.",
      },
    ],
  },
  "whatsapp-marketing": {
    name: "WhatsApp Marketing",
    description:
      "Reach your audience effectively with our WhatsApp marketing solutions, enabling direct and personalized communication for your business.",
    features: [
      "Bulk Messaging",
      "Automated WhatsApp Campaigns",
      "Personalized Message Templates",
      "WhatsApp Business API Integration",
      "Chatbot Automation",
      "Lead Generation & Nurturing",
      "Broadcast Messaging",
      "Multimedia Messaging (Images, Videos, PDFs)",
      "Customer Support Automation",
      "WhatsApp Group Marketing",
      "E-commerce WhatsApp Solutions",
      "Drip Campaigns",
      "CRM Integration",
      "Order & Booking Management",
      "Survey & Feedback Collection",
      "Appointment Reminders",
      "Analytics & Performance Tracking",
    ],
    faq: [
      {
        question: "How does WhatsApp marketing help my business?",
        answer:
          "WhatsApp marketing allows you to engage with customers directly, send bulk messages, automate responses, and increase customer retention with personalized interactions.",
      },
      {
        question: "Is WhatsApp marketing compliant with regulations?",
        answer:
          "Yes, we ensure compliance with WhatsApp's policies and use official WhatsApp Business API for secure and effective marketing campaigns.",
      },
    ],
  },
  "email-marketing": {
    name: "Email Marketing",
    description:
      "Boost your brand engagement and conversions with targeted email marketing campaigns designed to reach the right audience effectively.",
    features: [
      "Bulk Email Campaigns",
      "Personalized Email Marketing",
      "Automated Email Sequences",
      "Newsletter Creation & Management",
      "Drip Email Campaigns",
      "E-commerce Email Marketing",
      "Lead Nurturing Emails",
      "Transactional Emails",
      "Abandoned Cart Emails",
      "Customer Retention Emails",
      "Event & Webinar Invitations",
      "Survey & Feedback Emails",
      "Cold Email Outreach",
      "Email List Segmentation",
      "A/B Testing for Emails",
      "Email Performance Analytics",
      "Spam Compliance & Deliverability Optimization",
    ],
    faq: [
      {
        question: "How can email marketing benefit my business?",
        answer:
          "Email marketing helps you connect with your audience, nurture leads, drive sales, and maintain customer engagement through targeted and personalized messages.",
      },
      {
        question: "Do you provide email automation services?",
        answer:
          "Yes, we offer automated email sequences, drip campaigns, and workflow automation to ensure timely and effective communication with your audience.",
      },
    ],
  },
  "premium-software-available": {
    name: "Premium Software Available",
    description:
      "Get access to top-quality premium software solutions for various business and personal needs, ensuring efficiency and reliability.",
    features: [
      "Business Management Software",
      "CRM & ERP Solutions",
      "Accounting & Billing Software",
      "Graphic Design & Video Editing Software",
      "Antivirus & Security Software",
      "Project Management Tools",
      "E-commerce Software",
      "Marketing Automation Tools",
      "HR & Payroll Software",
      "POS (Point of Sale) Systems",
      "Educational & E-learning Software",
      "Data Analysis & Reporting Tools",
      "Custom Software Solutions",
      "Cloud-based Software Services",
      "Subscription & Licensing Management",
    ],
    faq: [
      {
        question: "Do you provide lifetime software licenses?",
        answer:
          "Yes, we offer both lifetime and subscription-based premium software licenses based on your requirements.",
      },
      {
        question: "Can I get a demo before purchasing?",
        answer:
          "Yes, we provide software demos and free trials where available, so you can evaluate the features before making a purchase.",
      },
    ],
  },

"all-legal-registration": {
    name: "All Types Registration",
    description:
      "We provide hassle-free registration services for businesses, individuals, and legal entities, ensuring compliance and accuracy.",
    features: [
      "Company Registration",
      "GST Registration",
      "Trademark & Copyright Registration",
      "Import-Export License (IEC)",
      "ISO Certification",
      "FSSAI Registration",
      "Udyam (MSME) Registration",
      "Tax & PAN Registration",
      "Property & Land Registration",
      "Vehicle Registration",
      "NGO & Trust Registration",
      "Startup & LLP Registration",
      "Shop & Establishment Registration",
      "Digital Signature Certificates (DSC)",
      "Legal Documentation & Compliance",
    ],
    faq: [
      {
        question: "How long does it take to complete a registration?",
        answer:
          "The time varies depending on the type of registration, but we ensure a quick and smooth process, usually within a few days.",
      },
      {
        question: "Do you provide consultancy for choosing the right registration?",
        answer:
          "Yes, we offer expert guidance to help you choose the best registration option based on your business or personal needs.",
      },
    ],
  },

"outdoor-advertising": {
    name: "Outdoor Advertising",
    description:
      "Maximize your brand visibility with impactful outdoor advertising solutions tailored to your audience and location.",
    features: [
      "Billboard Advertising",
      "Transit Advertising (Buses, Trains, Taxis)",
      "Hoardings & Posters",
      "Digital LED Screens",
      "Street Pole Advertising",
      "Mobile Van Advertising",
      "Airport Advertising",
      "Mall & Retail Advertising",
      "Cinema & Multiplex Advertising",
      "Bridge & Highway Advertisements",
      "Wall Painting & Murals",
      "Event & Exhibition Branding",
      "Public Transport Branding",
      "Bus Shelter & Kiosk Advertising",
      "Inflatable & Sky Balloon Advertising",
    ],
    faq: [
      {
        question: "What are the best locations for outdoor advertising?",
        answer:
          "We help you choose high-traffic locations, including highways, malls, transport hubs, and business districts for maximum brand exposure.",
      },
      {
        question: "Do you offer customized outdoor advertising solutions?",
        answer:
          "Yes, we provide tailored outdoor advertising strategies to meet your brand’s goals and target audience effectively.",
      },
    ],
  },
  "voice-over-service-": {
    name: "Voice Over Service",
    description:
      "Enhance your content with professional voice-over services, providing clear, engaging, and high-quality narration for various needs.",
    features: [
      "Commercial Voice Overs",
      "E-learning & Educational Narration",
      "Audiobook Recording",
      "Corporate & Explainer Videos",
      "Podcast & Radio Ads",
      "IVR & On-Hold Messages",
      "Character & Animation Voice Overs",
      "Documentary Narration",
      "Multilingual Voice Overs",
      "Dubbing & Localization",
      "YouTube & Social Media Voice Overs",
      "Advertisement & Promo Voice Overs",
      "Voice Modulation & Accents",
      "Background Music & Audio Mixing",
      "Custom Script Recording",
    ],
    faq: [
      {
        question: "What languages do you offer voice-over services in?",
        answer:
          "We provide voice-over services in multiple languages and accents, based on your project requirements.",
      },
      {
        question: "Can I request a sample before finalizing the project?",
        answer:
          "Yes, we offer sample voice recordings so you can choose the right tone and style for your project.",
      },
    ],
  },

"lead-genration": {
    name: "Lead Generation",
    description:
      "Boost your business growth with high-quality lead generation services, connecting you with potential customers and clients.",
    features: [
      "B2B & B2C Lead Generation",
      "Cold Calling & Telemarketing",
      "Email Lead Generation",
      "LinkedIn Lead Generation",
      "SEO & Organic Lead Generation",
      "Paid Ads Lead Generation",
      "Landing Page Optimization",
      "Webinar & Event Leads",
      "Lead Qualification & Nurturing",
      "CRM Integration",
      "Sales Funnel Development",
      "Lead Data Enrichment",
      "Survey & Market Research Leads",
      "Outbound & Inbound Lead Generation",
      "Local & Global Lead Targeting",
    ],
    faq: [
      {
        question: "How do you ensure lead quality?",
        answer:
          "We use advanced targeting, verification processes, and engagement strategies to ensure high-quality and relevant leads.",
      },
      {
        question: "Do you provide customized lead generation strategies?",
        answer:
          "Yes, we tailor our lead generation strategies based on your industry, target audience, and business goals.",
      },
    ],
  },

"packing-solutions": {
    name: "Packing Solutions",
    description:
      "Get reliable and efficient packing solutions for all types of goods, ensuring safety and durability during transit and storage.",
    features: [
      "Industrial Packing Solutions",
      "E-commerce Packaging",
      "Custom Box & Carton Packaging",
      "Corrugated Packaging",
      "Food & Beverage Packaging",
      "Fragile Item Packing",
      "Eco-Friendly Packaging Solutions",
      "Retail & Product Packaging",
      "Automotive & Heavy Machinery Packing",
      "Pharmaceutical & Medical Packaging",
      "Flexible & Plastic Packaging",
      "Luxury & Premium Packaging",
      "Customized Branding & Printing",
      "Bulk Packaging & Wholesale Solutions",
      "Logistics & Supply Chain Packaging",
    ],
    faq: [
      {
        question: "Do you offer custom packaging with branding?",
        answer:
          "Yes, we provide fully customizable packaging solutions with branding options such as logos, colors, and designs.",
      },
      {
        question: "Are your packaging solutions eco-friendly?",
        answer:
          "We offer a range of eco-friendly packaging materials, including biodegradable and recyclable options.",
      },
    ],
  },

"printing-service": {
    name: "Printing Service",
    description:
      "Get high-quality printing services for businesses and individuals, covering all types of printing needs with precision and affordability.",
    features: [
      "Offset & Digital Printing",
      "Business Cards & Stationery",
      "Brochure & Flyer Printing",
      "Banner & Large Format Printing",
      "Packaging & Label Printing",
      "Custom T-shirt & Merchandise Printing",
      "Book & Magazine Printing",
      "Wedding & Invitation Card Printing",
      "Vehicle Wrap Printing",
      "Promotional Material Printing",
      "Vinyl & Sticker Printing",
      "3D Printing Services",
      "Corporate Branding & Signage",
      "Billboard & Outdoor Advertising Prints",
      "High-Quality Paper & Finishing Options",
    ],
    faq: [
      {
        question: "Do you provide bulk printing services?",
        answer:
          "Yes, we handle bulk printing orders with competitive pricing and timely delivery.",
      },
      {
        question: "Can I get custom design support for printing?",
        answer:
          "Yes, we offer professional design assistance to create visually appealing and print-ready designs.",
      },
    ],
  }




};
