import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Main from "../page";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Events from "../page/events";
import Home from "../page/home";
import Service from "../page/service";
import { CurrentOffers } from "../data";
import Marquee from "react-marquee-slider";
import { BiSolidOffer } from "react-icons/bi";
import About from "../page/about";
import ServiceDetails from "../page/servicedetails";
import Cart from "../page/cart";
import Signin from "../page/auth/Signin";
import Signup from "../page/auth/Signup";
import CreateEvent from "../page/events/CreateEvent";
import Work from "../page/work";
import EventDetails from "../page/events/EventDetails";
import Products from "../page/prducts";
import Order from "../page/Order";
import Transitation from "../page/transitation";
import Admin from "../page/admin";
import CreateProduct from "../page/prducts/CreateProduct";

const ProtectedRoute = ({ element, isAdminRoute }) => {
  const token = localStorage.getItem("authToken");
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  if (!token) {
    return <Navigate to="/signin" />;
  }

  if (isAdminRoute && !isAdmin) {
    return <Navigate to="/" />;
  }

  return element;
};

const AuthRoute = ({ element }) => {
  const token = localStorage.getItem("authToken");

  if (token) {
    return <Navigate to="/" />;
  }

  return element;
};

const RouteComponent = () => {
  const location = useLocation();
  const token = localStorage.getItem("authToken");

  const hideOnRoutes = ["/signin", "/signup"];

  return (
    <div className="main-wrapper">
      {!hideOnRoutes.includes(location.pathname) && <Navbar />}

      <Routes>
        <Route path="/signin" element={<AuthRoute element={<Signin />} />} />
        <Route path="/signup" element={<AuthRoute element={<Signup />} />} />
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Home />} />
        <Route path="/event" element={<Events />} />
        <Route path="/event/:id" element={<EventDetails />} />
        <Route path="/product" element={<Products />} />
        <Route path="/service" element={<Service />} />
        <Route path="/work" element={<Work />} />
        <Route path="/about" element={<About />} />
        <Route path="/service-details/:id" element={<ServiceDetails />} />

        {/* Protected Routes */}
        <Route path="/cart" element={<ProtectedRoute element={<Cart />} />} />
        <Route
          path="/orders"
          element={<ProtectedRoute element={<Order />} />}
        />
        <Route
          path="/payment/transitation"
          element={<ProtectedRoute element={<Transitation />} />}
        />

        {/* Admin Routes */}
        <Route
          path="/admin/:id"
          element={<ProtectedRoute element={<Admin />} isAdminRoute={true} />}
        />
        <Route
          path="/event/create"
          element={
            <ProtectedRoute element={<CreateEvent />} isAdminRoute={true} />
          }
        />
        <Route
          path="/product/create"
          element={
            <ProtectedRoute element={<CreateProduct />} isAdminRoute={true} />
          }
        />
      </Routes>

      {!hideOnRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
};

export default RouteComponent;
