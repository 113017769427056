import React from "react";

const TeamCard = ({ data }) => {
  return (
    <div className="team-card-wrapper">
      <div class="card">
        <div class="profileImage">
          <img src={data?.url} alt="icon" className="image" lazy="loading" />
        </div>
        <div class="textContainer">
          <span class="name">{data?.name}</span>
          <span class="profile">{data?.job}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
