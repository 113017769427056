import React from "react";
import { poster1 } from "../../assets/image";

const CeoWord = () => {
  return (
    <div className="ceo-wrapper">
      <div className="ceo-home">
        <div className="image-container">
          <div className="image">
            <img src="https://kariox.in/wp-content/uploads/2024/07/arp2-1024x1024.jpeg" alt="icon" className="img" lazy="loading" />
          </div>
        </div>
        <div className="ceo-text">
          <span className="title">A Word From Our CEO</span>
          <span className="about">
            "Dear Esteemed Clients and Valued Team Members,
          </span>
          <span className="about">
            I am honored to lead Kariox Digital & Marketing Agency, a company
            dedicated to delivering innovative marketing solutions and
            exceptional customer service. As we venture into the future, I am
            excited to share our unwavering commitment to innovation,
            excellence, and client satisfaction.
          </span>
          <span className="about">
            Our team remains at the forefront of industry trends, ensuring that
            we provide cutting-edge marketing strategies tailored to meet the
            unique needs of our clients. Our success is intrinsically tied to
            the success of our clients, and we take great pride in fostering
            long-lasting partnerships built on trust and mutual growth."
          </span>
        </div>
      </div>
    </div>
  );
};

export default CeoWord;
