import React from "react";
import TeamCard from "../../component/cards/TeamCard";
import { TeamData } from "../../data";

const Team = () => {
  return (
    <div className="team-wrapper">
      <span className="heading">
        Meet Our <span className="hero">TEAM </span>
      </span>
      <div className="team-box-wrapper">
        {TeamData?.map((item, key) => {
          return <TeamCard data={item} id={key} />;
        })}
      </div>
    </div>
  );
};

export default Team;
