import React, { useState, useEffect } from "react";
import { logo } from "../assets/image";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaTicketAlt } from "react-icons/fa";
import { EventOffer } from "../data";
import MobileSidebar from "./MobileSidebar";
import ServiceModal from "./modal/ServiceModal";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { toast } from "react-toastify";
import { FaUserCircle, FaShoppingCart } from "react-icons/fa";
import UserDesktopPopup from "./UserDesktopPopup";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [event, setevent] = useState("Book Garba Ticket now");
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showuserPopup, setShowuserPopup] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
    handleCloseMobile();
  };

  const handleCloseMobile = () => {
    setIsMenuOpen(false);
  };

  const RandomEvent = () => {
    const randomEvent =
      EventOffer[Math.floor(Math.random() * EventOffer.length)];
    setevent(randomEvent);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      RandomEvent();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleClickService = () => {
    setShowServiceModal(!showServiceModal); // Toggle the modal on click
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/signin");
    handleCloseMobile();
    toast.success("Logout successful");
  };
  return (
    <div className="nav-container">
      <img
        src={logo}
        alt="logo"
        className="logo"
        //onClick={() => handleNavigation("/")}
       // onClick={() => handleLogout("/")}
      />

      {/* <span className="offer-text" onClick={() => handleNavigation("/event")}>
        <FaTicketAlt style={{ color: "red", width: 26, height: 26 }} />
        {event}
      </span> */}

      <div className="desktop-menu">
        <div className="item">
          <span
            className={`menu-name ${location.pathname === "/" ? "active" : ""}`}
            onClick={() => handleNavigation("/")}
          >
            HOME
          </span>
        </div>
        <div className="item" onMouseEnter={() => setShowServiceModal(true)}>
          <span
            className={`menu-name ${
              location.pathname === "/service" ? "active" : ""
            }`}
          >
            SERVICE
            <IoMdArrowDropdown size={20} />
          </span>
        </div>
        <div className="item">
          <span
            className={`menu-name ${
              location.pathname === "/about" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/about")}
          >
            ABOUT
          </span>
        </div>

        <div className="item">
          <span
            className={`menu-name ${
              location.pathname === "/demo" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/work")}
          >
            WORK
          </span>
        </div>
        {/* <div className="item">
          <span
            className={`menu-name ${
              location.pathname === "/product" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/product")}
          >
            PRODUCT
          </span>
        </div>
        <div className="item">
          <span
            className={`menu-name ${
              location.pathname === "/event" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/event")}
          >
            EVENTS
          </span>
        </div> */}
        {/* <div className="item">
          <FaUserCircle
            size={26}
            onClick={() => setShowuserPopup((prev) => !prev)}
          />
        </div> */}
      </div>
      <UserDesktopPopup
        onClose={() => setShowuserPopup(false)}
        show={showuserPopup}
      />
      <div
        className="mobile-menu-icon"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <FaBars size={24} />
      </div>

      {isMenuOpen && <MobileSidebar onClose={handleCloseMobile} />}

      {showServiceModal && (
        <ServiceModal onClose={() => setShowServiceModal(false)} />
      )}
    </div>
  );
};

export default Navbar;
