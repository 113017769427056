import React from "react";
import AboutIntro from "../home/component/AboutIntro";
import { poster, poster1 } from "../../assets/image";
import CeoWord from "./CeoWord";
import Projects from "./Projects";
import Team from "./Team";
const About = () => {
  return (
    <div className="about-main-wrapper">
      <div className="about-home">
        <div className="about-text">
          <span className="main-heading">
            Why <span className="main-heading special">Choose </span> us
          </span>
          <span className="title">
            We Transform Unvertainity Into Clear Strategies.
          </span>
          <span className="about">
            At Kariox, we go beyond providing services—we become your strategic
            partner in the ever-evolving digital landscape. Our team of seasoned
            professionals combines extensive experience, creativity, and
            technical expertise to deliver customized solutions that yield
            measurable results.
          </span>
        </div>
        <div className="image-container">
          <div className="image">
            <img src={poster1} alt="icon" className="img" />
          </div>
        </div>
      </div>
      <AboutIntro />

      <CeoWord />
      <Team />
    </div>
  );
};

export default About;
