import React, { useEffect, useState } from "react";
import NextButton from "../../../component/button/NextButton";
import { useNavigate } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";
import { ourService } from "../../../data";
import { heroSectionBg } from "../../../assets/image";
const Service = () => {
  const [index, setIndex] = useState(0);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/service");
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % ourService.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="service-home">
      <div className="serivice-text">
        <span className="main-heading">
          Best <span className="main-heading special">Solution for </span>
          <span className="main-heading service">
            <TextTransition springConfig={presets.wobbly}>
              {ourService[index]}
            </TextTransition>
          </span>
        </span>

        <span className="about">
          Empowering growth with innovative designs, dynamic websites, and
          impactful marketing.
        </span>
        {/* <NextButton text={"Read More"} onClick={handleNavigate} /> */}
      </div>
      <div className="banner-image">
        <img src={heroSectionBg} alt="bf" lazy="loading" className="hero-bg" />
      </div>
    </div>
  );
};

export default Service;
