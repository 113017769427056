import React, { useState } from "react";
import Input from "../../component/Input";
import { loginUser } from "../../api/Function";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from"../../component/Loader.js";
const Signin = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const[isLoading,setIsLoading]=useState(false);
  const [errors, setErrors] = useState({ mobileNumber: "", password: "" });
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (mobileNumber.length !== 10) {
      newErrors.mobileNumber = "Mobile number must be 10 digits.";
    }
    if (!password) {
      newErrors.password = "Password is required.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      let data = {
        mobile: mobileNumber,
        password: password,
      };
      setIsLoading(true);
      try {

        const res = await loginUser(data);
        if (res.status === 200) {

          localStorage.setItem("authToken", res.data.token);
          localStorage.setItem("userID", res.data.user._id);
          localStorage.setItem("isAdmin", res.data.user.isAdmin);
          localStorage.setItem("userName", res.data.user.name);
          localStorage.setItem("userAddress", res.data.user.address);
          toast.success("welcome again");
          navigate("/");
        }
      } catch (error) {
        console.log(error, "jhhh");
      }
      finally{
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="signin-container">
      <form className="signin-form" onSubmit={handleLogin}>
        <h2>Login</h2>

        <div className="form-group">
          <span>Mobile Number</span>
          <Input
            type="text"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Enter your mobile number"
            name="mobileNumber"
            id="mobileNumber"
            required
            errorText={errors.mobileNumber}
          />
        </div>

        <div className="form-group">
          <span>Password</span>
          <Input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            name="password"
            id="password"
            required
            errorText={errors.password}
          />
        </div>

        <button type="submit" className="signin-button">
          Sign In
        </button>
      </form>
      <span> Don't have account <span onClick={()=>{navigate("/signup")
}}> signup here</span></span>
      <Loader show={isLoading}/>
    </div>
  );
};

export default Signin;
