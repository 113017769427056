import React from "react";
import CommonSlider from "../../../utils/helper/CommonSlider";
import CustomerReviewCard from "../../../component/cards/CustomerReviewCard";
import { CustomerReviewCardata } from "../../../data";
const CustomerReview = () => {
  return (
    <div className="customer-review-container">
      <span className="heading">
        What Our <span className="heading main"> Customer</span> Say About Us
      </span>
      <span className="desc">
        Don't just take our word it - hear from those who have worked with us.
      </span>
      <CommonSlider
        data={CustomerReviewCardata}
        Component={CustomerReviewCard}
        cardWidth={360}
        AutoTime={5000}
        autoplay={true}
      />
    </div>
  );
};

export default CustomerReview;
