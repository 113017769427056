import React from "react";
import ServiceCard from "../../../component/cards/ServiceCard";
import { AllServiceData } from "../../../data";
const AllService = () => {
  return (
    <div className="all-service-conainer">
      <span className="heading-name">Our Services </span>
      <span className="about-service">
        We bring best services for you at a place
      </span>
      <div className="card-wrapper">
        {AllServiceData?.map((item) => {
          return <ServiceCard data={item} />;
        })}
      </div>
    </div>
  );
};

export default AllService;
