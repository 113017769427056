import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { serviceDetails } from "../../ServiceDetailsDummyData";
import { poster } from "../../assets/image";
import Contact from "../contact";
import CommonAccordian from "../../utils/helper/CommonAccordian";
import Projects from "../about/Projects";
const ServiceDetails = () => {
  const { id } = useParams();
  const serviceData = serviceDetails[id] || {
    name: "Service Not Found",
    description: "The service you are looking for does not exist.",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <div className="service-details-containers">
      <div className="container">
        <div className="service-banner">
          <div className="tex-container">
            <span className="name">{serviceData.name} </span>
            <span className="name soln">
              Explore our {serviceData.name} solutions.{" "}
            </span>
            <span className="name about">{serviceData.description}</span>
            <div className="button">Get your custom {serviceData.name} </div>
          </div>
          <div className="image-container">
            <div className="image">
              <img src={poster} alt="icon" className="img" />
            </div>
          </div>
        </div>
      </div>
      <div className="service-list-wrapper">
        <span className="heading">
          {" "}
          Our <span className="heading main">Services </span>include{" "}
        </span>
        <div className="service-list">
          {serviceData.features?.length ? (
            serviceData.features.map((feature, index) => (
              <div key={index} className="list-box">
                <span className="name">
                  {" "}
                  ({index + 1}) {feature}
                </span>
              </div>
            ))
          ) : (
            <span>No additional features available.</span>
          )}
        </div>
      </div>

      {id === "website-development" && <Projects />}

      <div className="faq-container">
        <span className="heading"> Common Query</span>
        {serviceData &&
          serviceData?.faq?.map((item, key) => {
            return <CommonAccordian data={item} index={key} />;
          })}
      </div>

      <Contact />
    </div>
  );
};

export default ServiceDetails;
