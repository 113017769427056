import React, { useState } from 'react';
import { compLogo9 } from '../../assets/image';

const ProductCard = ({ data ,onCartClick=()=>{}}) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (type) => {
    if (type === 'add') {
      setQuantity((prev) => prev + 1);
    } else if (type === 'subtract' && quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };
  return (
    <div className="product-card-wrapper">
     
      <img className="card-image" src={data?.images[0] || compLogo9} alt="Children Shirt" loading='lazy' />
      <div className="card-header">
        <span className="title">{data?.name}</span>
        <span className="subtitle">{data?.description}</span>
      </div>
      <div className="card-body">
        <div className="price-rating">
          <span className="price">₹ {data?.price}</span>
          <span className="rating">★ ★ ★ ☆ ☆</span>
        </div>
        <div className="sizes">
          <span className="label">SIZE</span>
          <div className="options">
            <button>XS</button>
            <button>S</button>
            <button>M</button>
            <button>L</button>
            <button>XL</button>
          </div>
        </div>
        <div className="colors">
          <span className="label">COLORS</span>
          <div className="options">
            <button className="color purple"></button>
            <button className="color black"></button>
          </div>
        </div>
        <div className="quantity-cart">
          <div className="quantity">
          <button onClick={() => handleQuantityChange('subtract')}>−</button>
          <span className="quantity-value">{quantity}</span>
          <button onClick={() => handleQuantityChange('add')}>+</button>
          </div>
          <button className="add-to-cart"
            onClick={() => onCartClick(data, quantity)}
           >ADD TO CART</button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
