import React from 'react';

const Table = ({ data = [] ,type=""}) => {
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div className="table-container">
{data?.length>0?
      <table className="responsive-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header.charAt(0).toUpperCase() + header.slice(1)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {headers.map((header, headerIndex) => (
                <td key={headerIndex}>
                  {Array.isArray(item[header])
                    ? item[header].length
                    : header === "bookingtime" // Format date for bookingtime
                    ? new Date(item[header]).toLocaleString()
                    : item[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      :<span> No Data Found</span>
      }
    </div>
  );
};

export default Table;
