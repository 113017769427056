import React, { useState } from "react";
import AddCartButton from "../button/AddCartButton";
import { FaMinus, FaPlus } from "react-icons/fa";
import { eventposter2 } from "../../assets/image";
import { SlCalender } from "react-icons/sl";
import { FaSignsPost } from "react-icons/fa6";
import { MdAccessTime } from "react-icons/md";
import { FaHourglassEnd } from "react-icons/fa";
import { GoPeople } from "react-icons/go";
import { GiGlassCelebration } from "react-icons/gi";
import { IoLocationOutline } from "react-icons/io5";
import LIke from "../button/LIke";
import Skeleton from "react-loading-skeleton";
import { ChangeDataFormat } from "../../utils/helper/function";
import { MdDelete } from "react-icons/md";
import { addTocart } from "../../api/Function";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EventCard = ({
  data = {},
  index,
  ticketCount,
  onTicketCountChange,
  isLoader = false,
  onDelete = () => {},
  viewDetails = () => {},
}) => {
    const [isAdding, setIsAdding] = useState(false);
      const [quantity, setQuantity] = useState(1);
    
    const userId = localStorage.getItem("userID");
  const navigate = useNavigate();
  const handleQuantityChange = (type) => {
    if (type === 'add') {
      setQuantity((prev) => prev + 1);
    } else if (type === 'subtract' && quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };
    const handleCart = async (data) => {
      setIsAdding(true);
      let payload = {
        userId,
        eventId: data?._id,
        ticketCount: 1,
      };
      try {
        const result = await addTocart(payload);
        if (result.status === 200) {
          toast.success("Event added in cart successfully");
         // navigate("/cart");
        } else {
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        setIsAdding(false);
      }
    };
  const increment = () => {
    onTicketCountChange(index, 1);
  };

  const decrement = () => {
    onTicketCountChange(index, -1);
  };
  return (
    <div className="event-card-container">
      <div className="poster-container">
        {isLoader ? (
          <Skeleton height={150} />
        ) : (
          <img
            src={data?.image || eventposter2}
            alt="poster"
            className="poster"
            loading="lazy"
          />
        )}
      </div>

      <div className="price-details">
        <div className="one">
          <div className="live"></div>
          <span className="price">
            ₹ {isLoader ? <Skeleton width={100} /> : data.price}
          </span>
        </div>
        <div className="two">
          {data?.bookingCount !== 0 && !isLoader && (
            <LIke count={data?.bookingCount} />
          )}
        </div>
      </div>

      <div className="booking-details">
        <div className="box">
          <FaSignsPost />
          <span className="text">
            {isLoader ? <Skeleton width={200} /> : data.eventName}
          </span>
        </div>

        <div className="box">
          <SlCalender />
          <span className="text">
            {isLoader ? <Skeleton width={100} /> : ChangeDataFormat(data?.date)}
          </span>
        </div>

        <div className="box">
          <MdAccessTime />
          <span className="text">
            {isLoader ? <Skeleton width={100} /> : data.starttime}
          </span>
        </div>

        <div className="box">
          <FaHourglassEnd />
          <span className="text">
            {isLoader ? <Skeleton width={100} /> : data?.duration}
          </span>
        </div>

        <div className="box">
          <GoPeople />
          <span className="text">
            {isLoader ? <Skeleton width={100} /> : data?.totalticket}
          </span>
        </div>

        <div className="box">
          <GiGlassCelebration />
          <span className="text">
            {isLoader ? <Skeleton width={150} /> : data.about}
          </span>
        </div>

        <div className="box">
          <IoLocationOutline />
          <span className="text">
            {isLoader ? <Skeleton width={150} /> : data.location}
          </span>
        </div>
        {!isLoader && (
          <span className="more" onClick={() => viewDetails()}>
            view details...
          </span>
        )}
      </div>

      <div className="event-book-box">
        <div className="event-book">
{   isAdding?   <Skeleton width={140} height={40} />  :       <AddCartButton Click={() => handleCart(data)} />
}          <MdDelete
            color="red"
            style={{ width: 28, height: 28, cursor: "pointer" }}
            onClick={() => onDelete(data?._id)}
          />
          <div className="cart-number-box">
            <div className="box" onClick={() => handleQuantityChange('subtract')}>
              <FaMinus style={{ color: "#fff" }} />
            </div>
            <div className="box">
              <span className="number">{quantity}</span>
            </div>
            <div className="box" onClick={() => handleQuantityChange('add')}>
              <FaPlus style={{ color: "#fff" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
