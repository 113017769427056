import React, { useState } from "react";
import Input from "../../component/Input";
import { eventCreate } from "../../api/Function";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/Loader";

const CreateEvent = () => {
  const navigate = useNavigate();
  const Id = localStorage.getItem("userID");
  const [formData, setFormData] = useState({
    about: "",
    totalticket: "",
    price: "",
    eventName: "",
    location: "",
    date: "",
    starttime: "",
    duration: "",
    specialguest: "",
    contactno: "",
    createdBy: Id,
  });

  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const[loading,setIsLoading]=useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key].trim()) {
        newErrors[key] = `${key} is required.`;
      }
    });
    if (!image) {
      newErrors.image = "Image is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
          data.append(key, formData[key]);
        });

        const fileInput = document.getElementById("image-upload");
        if (fileInput?.files[0]) {
          data.append("image", fileInput.files[0]);
        }

        const res = await eventCreate(data);

        if (res.status === 201) {
          toast.success("Event created successfully");
          navigate("/event");
        }
      } catch (error) {
        console.error("Error creating event:", error);
        toast.error("Failed to create event");
      }
      finally{setIsLoading(false)}
    }
  };

  return (
    <div className="create-event-wrapper">
      <div className="container">
        <h2 className="title">Create Event</h2>
        <div className="input-wrapper">
          <div className="input-box">
            <Input
              label="Add Event Description"
              name="about"
              value={formData.about}
              onChange={handleInputChange}
              placeholder="About the Event"
              errorText={errors.about}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Total Tickets"
              name="totalticket"
              type="number"
              value={formData.totalticket}
              onChange={handleInputChange}
              placeholder="Enter total tickets"
              errorText={errors.totalticket}
            />
          </div>
          <div className="input-box">
            <Input
              label=" Add Ticket Price"
              name="price"
              type="number"
              value={formData.price}
              onChange={handleInputChange}
              placeholder="Enter ticket price"
              errorText={errors.price}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Event Name"
              name="eventName"
              value={formData.eventName}
              onChange={handleInputChange}
              placeholder="Event Name"
              errorText={errors.eventName}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              placeholder="Location"
              errorText={errors.location}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Event Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleInputChange}
              errorText={errors.date}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Start Time"
              name="starttime"
              type="time"
              value={formData.starttime}
              onChange={handleInputChange}
              errorText={errors.starttime}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Duration"
              name="duration"
              value={formData.duration}
              onChange={handleInputChange}
              placeholder="Duration"
              errorText={errors.duration}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Special Guest"
              name="specialguest"
              value={formData.specialguest}
              onChange={handleInputChange}
              placeholder="Special Guest"
              errorText={errors.specialguest}
            />
          </div>
          <div className="input-box">
            <Input
              label="Add Contact Number"
              name="contactno"
              type="tel"
              value={formData.contactno}
              onChange={handleInputChange}
              placeholder="Contact Number"
              errorText={errors.contactno}
            />
          </div>
          <div className="image-box">
            <label htmlFor="image-upload" className="image-label">
              Upload Event Image
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {errors.image && <p className="error-text">{errors.image}</p>}
          </div>
        </div>

        <button className="submit-button" onClick={() => handleSubmit()}>
          Submit
        </button>
        <Loader show={loading}/>

      </div>
    </div>
  );
};

export default CreateEvent;
