import React, { useState } from 'react'
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { customSelctStyles ,ourService} from '../../data';
import { toast } from "react-toastify";

const ContactModal = ({onClose=()=>{}}) => {
    const [isloading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "", 
      message: "",
      service: "",
    });

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSelectChange = (selectedOption) => {
      setFormData((prev) => ({
        ...prev,
        service: selectedOption ? selectedOption.value : ""
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      // Check if phone number length is 10 digits
      if (formData.phone.length !== 10) {
        toast.warning("Phone number must be 10 digits.");
        return;  // Stop the form submission if validation fails
      }

      const form = new FormData();

      // Append the form data
      form.append("name", formData.name);
      form.append("email", formData.email);
      form.append("phone", formData.phone); 
      form.append("message", formData.message);
      form.append("service", formData.service);

      form.append("access_key", "df9ff080-0dc4-4f14-83bd-31a0a19b535a"); // Replace with your Web3Forms access key

      const json = JSON.stringify(Object.fromEntries(form));
      setIsLoading(true);
      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        });
        const resData = await response.json();

        if (resData.success) {
          toast.success("Thank you, we will get back to you.");
          setFormData({ name: "", email: "", phone: "", message: "", service: "" });
        } else {
          toast.error("Oops, something went wrong. Please try again.");
        }
      } catch (error) {
        toast.warning("Oops, something went wrong. Please try again.");
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
        onClose();
      }
    };

    return (
      <div className='booking-modal-main' onClick={onClose}>
        <div className='modal-inside' onClick={(e) => e.stopPropagation()}>
          <div className='wrapper'>
            <IoMdClose className='cross' style={{ color: "red", width: 26, height: 26 }} onClick={onClose} />
            <span className="contact-para">
              *Please fill out the form.
            </span>
            <form className="contact-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                className="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Select
                name="service"
                options={ourService.map((service) => ({ value: service, label: service }))}
                onChange={handleSelectChange}
                value={formData.service ? { value: formData.service, label: formData.service } : null}
                placeholder="Select a Service"
                styles={customSelctStyles}
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                className="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone Number"
                className="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                rows="5"
                placeholder="Your Message"
                className="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <button type="submit" className="submitbtn">
                {isloading ? "Sending..." : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
};

export default ContactModal;
