export const ChangeDataFormat = (data) => {
  const date = new Date(data);

  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(date);

  return formattedDate;
};

export const utcToIstTime = (time) => {
  const date = new Date(time);

  // Convert to IST (UTC +5:30)
  const istOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds
  const istDate = new Date(date.getTime() + istOffset);

  // Format hours and minutes
  let hours = istDate.getHours();
  const minutes = istDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format

  // Format date
  const day = istDate.getDate();
  const month = istDate.toLocaleString('default', { month: 'short' });
  const year = istDate.getFullYear();

  return `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}, ${day} ${month} ${year}`;
};


