import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { customSelctStyles, ourService } from "../../data";
import { gmail, phone, location } from "../../assets/image";
import { IoCallSharp } from "react-icons/io5";

import { SiGmail } from "react-icons/si";
import { IoLocationSharp } from "react-icons/io5";

const Contact = () => {
  const [isloading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    service: "QUERY FOR SERVICE",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      service: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if phone number length is 10 digits
    if (formData.phone.length !== 10) {
      toast.warning("Phone number must be 10 digits.");
      return; // Stop the form submission if validation fails
    }

    const form = new FormData();

    // Append the form data
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("phone", formData.phone);
    form.append("message", formData.message);
    form.append("service", formData.service);

    form.append("access_key", "f6ba0ce9-bcfe-4d24-9ea2-4d80a780a190"); // Replace with your Web3Forms access key

    const json = JSON.stringify(Object.fromEntries(form));
    setIsLoading(true);
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });
      const resData = await response.json();

      if (resData.success) {
        toast.success("Thank you, we will get back to you.");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          service: "",
        });
      } else {
        toast.error("Oops, something went wrong. Please try again.");
      }
    } catch (error) {
      toast.warning("Oops, something went wrong. Please try again.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="contact-container">
      <span className="text">
        Let's Discuss your <span className="text active"> Project</span>
      </span>
      <div className="details-box">
        <div className="cotact-container-box">
          <div className="box ">
            <IoCallSharp size={40} />
            <span className="detail"> +91 7307167094</span>
          </div>
          <div className="box ">
            <SiGmail size={40} />
            <span className="detail"> karioxvaranasi@gmail.com</span>
          </div>
          <div className="box ">
            <IoLocationSharp size={40} />
            <span className="detail"> Chandel Niwas ,Ramna, Naipura Khurd ,Varansi</span>
          </div>
        </div>
        <div className="contact-form-box">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <input
              type="email"
              name="email"
              placeholder="Your Email"
              className="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Your Phone Number"
              className="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              rows="5"
              placeholder="Your Message"
              className="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button type="submit" className="submitbtn">
              {isloading ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
